import React, { createContext, useEffect, useState } from "react";
import {
  CartLineItem,
  CartPrice,
  CartV2,
} from "../pages/checkout/model/CartModel";
import { Cart } from "../pages/product/models/ProductModel";
import { removeFractionalPart } from "../util/helpers";

export type CartContextType = {
  shopifyCartConfig: any;
  setShopifyCartConfig: (cart: Cart) => void;
  cart: CartV2;
  updateCart: (item: CartLineItem) => void;
  itemsInCart: () => number;
  setCartLineItems: (lineItems: CartLineItem[]) => void;
  discountHasUpdated: boolean;
  setDiscountHasUpdated: (discountHasUpdated: boolean) => void;
  cartPrice: CartPrice;
  setCart: (cart: CartV2) => void;
};

export const CartContext = createContext<CartContextType>({
  shopifyCartConfig: {},
  setShopifyCartConfig: () => "",
  cart: { lineItems: [] },
  updateCart: () => "",
  itemsInCart: () => 0,
  setCartLineItems: (_li) => null,
  cartPrice: { original: 0, discounted: 0 },
  discountHasUpdated: false,
  setDiscountHasUpdated: () => "",
  setCart: () => null,
});

export const CartContextProvider: ({
  children,
}: {
  children: React.ReactNode;
}) => JSX.Element = ({ children }) => {
  const [shopifyCartConfig, setShopifyCartConfig] = useState<Cart>();
  const [cart, setCart] = useState<CartV2>({ lineItems: [] });
  const [discountHasUpdated, setDiscountHasUpdated] = useState(false);
  const [cartPrice, setCartPrice] = useState<{
    original: number;
    discounted: number;
  }>({ original: 0.0, discounted: 0.0 });

  useEffect(() => {
    let original = 0,
      discounted = 0;
    cart.lineItems.forEach((item) => {
      original += +item.variant.price * item.quantity;
      discounted +=
        (item.plan_id
          ? +item.variant.subscription_price
          : +item.variant.discounted_price > 0
          ? +item.variant.discounted_price
          : +item.variant.price) * item.quantity;
    });
    const hasAnyDiscount = cart.lineItems.some(
      (item) => item.variant.discounted_price !== undefined || item?.plan_id
    );
    setCartPrice({
      original: +removeFractionalPart(original.toFixed(2)),
      discounted: hasAnyDiscount
        ? +removeFractionalPart(discounted.toFixed(2))
        : 0,
    });
  }, [cart]);

  const updateCart = (lineItem: CartLineItem) => {
    const li = cart?.lineItems?.filter(
      ({ variant }) => variant?.variant_id !== lineItem?.variant?.variant_id
    );

    if (lineItem.quantity < 1) {
      setCart({ lineItems: li });
    } else {
      const found = cart.lineItems.findIndex(
        ({ variant }) => variant.variant_id === lineItem.variant.variant_id
      );

      if (found !== -1) {
        var tmp = [...cart.lineItems];

        tmp.splice(found, 1, lineItem);

        setCart({ lineItems: tmp });
      } else {
        setCart({ lineItems: [...li, lineItem] });
      }
    }
  };

  const setCartLineItems = (lineItems: CartLineItem[]) => {
    setCart({ lineItems });
  };

  const itemsInCart = () =>
    cart.lineItems.reduce((prev, curr) => prev + curr.quantity, 0);

  return (
    <CartContext.Provider
      value={{
        setDiscountHasUpdated,
        discountHasUpdated,
        shopifyCartConfig,
        setShopifyCartConfig,
        cart,
        setCart,
        updateCart,
        itemsInCart,
        setCartLineItems,
        cartPrice,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
