import { useState, useContext, useEffect } from "react";
import NavBar2 from "../../components/Navbar2";
import { SiteConfigurationContext } from "../../contexts/SiteConfigurationContext";
import { useParams } from "react-router-dom";
import { getReminderInfo } from "./api/reminderApi";
import { Loading } from "../../components/Loading";

export const ReminderInviation = () => {
  const { siteConfiguration } = useContext(SiteConfigurationContext);
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [error, setError] = useState("");

  useEffect(() => {
    if (params.reminder_email) {
      getReminder(params.reminder_email);
    }
    // eslint-disable-next-line
  }, []);

  const getReminder = (reminderEmail: string) => {
    // just to test
    // const urlString = 'https://api.assistalong.com/order_status_page?variant_ids=gid://shopify/ProductVariant/43334337265881%7C1&domain=bouviour.myshopify.com&name=Danial%20Ehyaie&shopify_customer_id=null&order_id=5043483148505&email=danial%2Bqa1%40assistalong.com&shipping_phone=+14158140020';
    // const container = document.createElement("object")
    // const containerBody = document.getElementById('reminder-content');
    // let body = document.createElement("body")
    // body.setAttribute("id", "assistalong-reminder-body");

    // container.setAttribute("style", "overflow:hidden;min-width:101%;margin-left: -5px;margin-top: 13px;");
    // if (containerBody) {
    //   container.setAttribute("type", "text/html")
    //   container.setAttribute("data", urlString);
    //   body.append(container)
    //   containerBody.append(body)
    // }
    // just to test

    setLoading(true);
    getReminderInfo(reminderEmail)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          const {
            domain,
            email,
            name,
            order_id,
            shipping_phone,
            shopify_customer_id,
            variant_ids,
            iframe,
          } = res.data;
          const urlObj = new URL(`${API_URL}/order_status_page`);
          urlObj.searchParams.append("domain", domain);
          urlObj.searchParams.append("email", email);
          urlObj.searchParams.append("name", name);
          urlObj.searchParams.append("order_id", order_id);
          urlObj.searchParams.append("shipping_phone", shipping_phone);
          urlObj.searchParams.append("iframe", iframe.toString());
          urlObj.searchParams.append(
            "shopify_customer_id",
            shopify_customer_id
          );
          urlObj.searchParams.append("variant_ids", variant_ids);
          const urlString = decodeURIComponent(urlObj.toString());
          // console.log(decodeURIComponent(urlString))
          // const urlString = 'https://api.assistalong.com/order_status_page?variant_ids=gid://shopify/ProductVariant/43334337265881%7C1&domain=bouviour.myshopify.com&name=Danial%20Ehyaie&shopify_customer_id=null&order_id=5043483148505&email=danial%2Bqa1%40assistalong.com&shipping_phone=+14158140020';
          // const urlString = 'https://d81c-182-187-142-251.ngrok.io/order_status_page?=&domain=null&email=hammad@phaedrasolutions.com&name=Hammad+Maqbool&order_id=5198216298816&shipping_phone=&shopify_customer_id=6484288373056&variant_ids=gid://shopify/ProductVariant/43641346064704|1,gid://shopify/ProductVariant/43641346031936|1,gid://shopify/ProductVariant/43641345868096|1';
          const container = document.createElement("object");
          const containerBody = document.getElementById("reminder-content");
          // container.setAttribute("id", "assistalong-reminder-body-object");
          // let body = document.createElement("body");

          container.setAttribute(
            "style",
            "overflow:hidden;min-width:101%;margin-left: -5px;margin-top: 13px;min-height:900px"
          );
          if (containerBody) {
            containerBody.setAttribute("id", "assistalong-reminder-body");
            container.setAttribute("type", "text/html");
            container.setAttribute("data", urlString);
            // body.append(container);
            // containerBody.append(body);
            containerBody.append(container);
          }
        }
      })
      .catch((e) => {
        if (e && e.response && e.response.status === 422) {
          setError(e.response.data.error);
        }
        setLoading(false);
      });
  };
  // https://api.assistalong.com/order_status_page?variant_ids=gid://shopify/ProductVariant/43334337265881%7C1&domain=bouviour.myshopify.com&name=Danial%20Ehyaie&shopify_customer_id=null&order_id=5043483148505&email=danial%2Bqa1%40assistalong.com&shipping_phone=+14158140020

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <NavBar2
            middle={
              <div className="flex relative pl-4 w-full py-4 bg-white sm:justify-end justify-between h-16">
                {siteConfiguration?.image_url && (
                  <img
                    className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-8"
                    src={siteConfiguration?.image_url}
                    alt="Brand banner"
                  ></img>
                )}
              </div>
            }
          />
          {error ? (
            <div
              className="relative"
              style={{ backgroundColor: siteConfiguration?.secondary_color }}
            >
              <div className="max-w-6xl bg-h-without-banner px-5 flex mx-auto justify-center items-center">
                <h2 className="text-xl font-boldAvenir md:font-blackAvenir md:!text-4xl font-semibold mb-6 xl:!mb-14">
                  {error}
                </h2>
              </div>
            </div>
          ) : (
            <div
              className="cart-product-wrap relative sm:pb-28 pb-[100px]"
              style={{ backgroundColor: siteConfiguration?.secondary_color }}
            >
              <div className="max-w-6xl px-5 flex mx-auto justify-center">
                <div
                  className="sm:w-[571px] w-full h-full"
                  id="reminder-content"
                ></div>
              </div>

              {/* <div className="rv_footer w-full absolute bottom-8 left-1/2 -translate-x-1/2">
                  <Footer />
                </div> */}
            </div>
          )}
        </>
      )}
    </>
  );
};
