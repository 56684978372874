import axios from "axios";
import {
  CreateReminderReq,
  ReminderIFrameDetails,
  RepeatReminderResponse,
  UpdateReminderReq,
} from "../model/ReminderModel";

const API_URL = process.env.REACT_APP_API_URL;

export const getReminder = (uuid: string, utmMedium?: string) => {
  const url = new URL(`${API_URL}/repeat_reminders/${uuid}`);
  if (utmMedium) {
    return axios.get<RepeatReminderResponse>(
      `${url.toString()}?utm_medium=${utmMedium}`
    );
  } else {
    return axios.get<RepeatReminderResponse>(`${url.toString()}`);
  }
};

export const getReminders = (phone: string) => {
  const url = new URL(`${API_URL}/customer_users/${phone}/repeat_reminders`);
  return axios.get<RepeatReminderResponse[]>(url.toString());
};

export const updateReminder = (
  uuid: string,
  updateReq: UpdateReminderReq,
  utmMedium: string
) => {
  const url = new URL(`${API_URL}/repeat_reminders/${uuid}`);
  return axios.put<RepeatReminderResponse>(url.toString(), {
    repeat_reminder: { ...updateReq },
    utm_medium: utmMedium,
  });
};

export const createReminder = (phone: string, req: CreateReminderReq) => {
  const url = new URL(`${API_URL}/customer_users/${phone}/repeat_reminders`);
  return axios.post<RepeatReminderResponse>(url.toString(), {
    repeat_reminder: req,
  });
};

export const pauseReminder = (uuid: string) => {
  const url = new URL(`${API_URL}/repeat_reminders/${uuid}/pause`);
  return axios.put<RepeatReminderResponse>(url.toString());
};

export const getReminderInfo = (email: string) => {
  return axios.post<ReminderIFrameDetails>(
    `${API_URL}/repeat_reminders/invitation_email_data?email=${email}`,
    { business_id: process.env.REACT_APP_BUSINESS_ID }
  );
};
