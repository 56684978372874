const Footer = () => {
  return (
    <>
      <div className="summary-footer-wrap relative bottom-0 p-2 pb-5 w-full md:pt-[57px] pt-[34px] max-w-6xl mx-auto flex md:pr-4 pr-0">
        <div className="w-full flex justify-center relative items-center xl:ml-20 lg:ml-7">
          <span className="mr-1 pr-2 text-xxs text-designerGray-light">
            Powered by
          </span>
          <img className="object-contain h-6" src="\assistalong 2.svg" alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
