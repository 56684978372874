import { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { CartResponse } from "../checkout/model/CheckoutModel";
import {
  Product,
  ProductPageConfiguration,
  ProductPageConfigurationV2,
  ProductVariant,
} from "../../models/ConfigurationModel";
import { saveProduct } from "../../api/customerUserApi";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";

import { CartContext } from "../../contexts/CartContext";
import { SiteConfigurationContext } from "../../contexts/SiteConfigurationContext";

import { Loading } from "../../components/Loading";
import { CartView } from "./components/CartView";
import { CartPrice } from "./components/CartPrice";
import { ProductCard } from "./components/ProductCard";
import { useTrackPageVisit } from "./components/use-track-view";
import { useSaveHomepage } from "./components/use-save-homepage";
import NavBar2 from "../../components/Navbar2";
import { getSmartReminderConfig } from "../../api/ConfigurationRequests";
import { CartLineItem } from "../checkout/model/CartModel";
import {
  // calculateDiscount,
  calculateSubscriptionPrice,
  getDefualtSubscription,
  getDiscountPrice,
  getPurchaseTime,
  removeFractionalPart,
} from "../../util/helpers";

export const SmartCheckoutPage = () => {
  const navigate = useNavigate();
  const [searchPararms] = useSearchParams();
  const utmMedium = searchPararms.get("utm_medium");

  const [config, setConfig] = useState<ProductPageConfigurationV2>();
  const [completed, setCompleted] = useState(false);
  const [cartId, setCartId] = useState<string>();
  const [error, setError] = useState<string>("");
  const [shopifyDiscountCode, setShopifyDiscountCode] = useState<string>("");
  const {
    customerUser,
    refresh,
    loading,
    error: customerError,
  } = useContext(CustomerUserContext);
  // change for
  const [buyLaterProductIds, setBuyLaterProductIds] = useState<string[]>([]);
  const { siteConfiguration } = useContext(SiteConfigurationContext);
  const {
    cart,
    itemsInCart,
    setCartLineItems,
    setDiscountHasUpdated,
    discountHasUpdated,
  } = useContext(CartContext);

  const { productId, smartCheckoutName, cuuid } = useParams<{
    variant: string;
    productId?: string;
    smartCheckoutName: string;
    cuuid: string;
  }>();
  const { isLoading } = useAuth0();
  if (cuuid === "null") {
    if (siteConfiguration.shopify_domain) {
      window.location.href = `https://${siteConfiguration.shopify_domain}`;
    }
    // console.log(siteConfiguration.shopify_domain)
  } else {
    if (customerError) {
      navigate("/404");
    }
  }

  const onSubmit = (c: CartResponse) => {
    setCompleted(true);
    setCartId(c.uuid);
  };

  useSaveHomepage(true);

  const getVariants = (obj: Product) => {
    const productVariants = obj?.variants
      .filter((item) =>
        item.selected_options.some(
          (item) => item.name === "Title" && item.value !== "Default Title"
        )
      )
      .map((item) => item.id);
    const finalVariants =
      productVariants.length > 0
        ? obj?.variants.filter(
            (item) => !productVariants.includes(item.id) && +item.price !== 0
          )
        : obj?.variants;
    if (finalVariants.length > 1) {
      return finalVariants
        ?.filter(
          (childObj: ProductVariant) =>
            childObj.variant_name !== "Default Title"
        )
        .map((item: ProductVariant) => {
          const subscription_prices = obj.subscription_options.map(
            (subOption) => {
              return {
                ...subOption,
                price: calculateSubscriptionPrice(
                  item.price,
                  subOption.value,
                  subOption.type
                ),
              };
            }
          );

          // if (obj.selected_discount_code && obj.apply_discount) {
          //   const discounted_price = calculateDiscount(
          //     item.price,
          //     obj.selected_discount_code
          //   );
          //   return {
          //     ...item,
          //     main_product_id: obj.product_id,
          //     price: removeFractionalPart(item.price),
          //     discounted_price: removeFractionalPart(
          //       discounted_price.toString()
          //     ),
          //     subscription_prices,
          //   };
          // } else {
          return {
            ...item,
            main_product_id: obj.product_id,
            price: removeFractionalPart(item.price),
            subscription_prices,
          };
          // }
        });
    } else {
      return finalVariants.map((item: ProductVariant) => {
        const subscription_prices = obj.subscription_options.map(
          (subOption) => {
            return {
              ...subOption,
              price: calculateSubscriptionPrice(
                item.price,
                subOption.value,
                subOption.type
              ),
            };
          }
        );
        // if (obj.selected_discount_code && obj.apply_discount) {
        //   const discounted_price = calculateDiscount(
        //     item.price,
        //     obj.selected_discount_code
        //   );
        //   return {
        //     ...item,
        //     main_product_id: obj.product_id,
        //     price: removeFractionalPart(item.price),
        //     discounted_price: removeFractionalPart(discounted_price.toString()),
        //     subscription_prices,
        //   };
        // } else {
        return {
          ...item,
          main_product_id: obj.product_id,
          price: removeFractionalPart(item.price),
          subscription_prices,
        };
        // }
      });
    }
  };

  const totalItemQuantity = useMemo(() => {
    return cart.lineItems.reduce((acc, cur) => {
      return acc + cur.quantity;
    }, 0);
  }, [cart]);

  useEffect(() => {
    if (!config) return;
    const cartLineItems = [...cart.lineItems];
    const tempConfigProducts = [...config.products];
    tempConfigProducts.forEach((configProduct) => {
      if (configProduct.discount_type_flag && configProduct.add_discount_flag) {
        if (configProduct.discount_min_purchase_type === "quantity") {
          // implement formula on discount_min_purchase_qty
          const cartProductQuantity = cart.lineItems
            .filter(
              (item) =>
                item.variant.main_product_id ===
                configProduct.product.product_id
            )
            .map((item) => item.quantity)
            .reduce((acc, cur) => {
              return acc + cur;
            }, 0);
          if (cartProductQuantity >= configProduct.discount_min_purchase_qty) {
            // set discount has updated if not already
            if (!discountHasUpdated) {
              setDiscountHasUpdated(true);
            }
            // apply discount to cart products and config product
            configProduct.product.variants.forEach(
              (variant) =>
                (variant.discounted_price = getDiscountPrice(
                  variant.price,
                  configProduct.discount_type,
                  configProduct.discount_type_value
                ))
            );
            cartLineItems.forEach(
              (lineItem) =>
                (lineItem.variant.discounted_price = getDiscountPrice(
                  lineItem.variant.price,
                  configProduct.discount_type,
                  configProduct.discount_type_value
                ))
            );
          } else {
            if (discountHasUpdated) {
              configProduct.product.variants.forEach(
                (variant) => (variant.discounted_price = "0")
              );
              cartLineItems.forEach(
                (lineItem) => (lineItem.variant.discounted_price = "0")
              );
            }
          }
        } else {
          // implement formula on discount_min_purchase_amount
          const cartProductPrice = cart.lineItems
            .filter(
              (item) =>
                item.variant.main_product_id ===
                configProduct.product.product_id
            )
            .map((item) => item.quantity * +item.variant.price)
            .reduce((acc, cur) => {
              return acc + cur;
            }, 0);
          if (cartProductPrice >= configProduct.discount_min_purchase_amount) {
            // set discount has updated if not already
            if (!discountHasUpdated) {
              setDiscountHasUpdated(true);
            }
            // apply discount to cart products and config product
            configProduct.product.variants.forEach(
              (variant) =>
                (variant.discounted_price = getDiscountPrice(
                  variant.price,
                  configProduct.discount_type,
                  configProduct.discount_type_value
                ))
            );
            cartLineItems.forEach(
              (lineItem) =>
                (lineItem.variant.discounted_price = getDiscountPrice(
                  lineItem.variant.price,
                  configProduct.discount_type,
                  configProduct.discount_type_value
                ))
            );
          } else {
            if (discountHasUpdated) {
              configProduct.product.variants.forEach(
                (variant) => (variant.discounted_price = "0")
              );
              cartLineItems.forEach(
                (lineItem) => (lineItem.variant.discounted_price = "0")
              );
            }
          }
        }
      }
    });

    setConfig({ ...config, products: tempConfigProducts });
    setCartLineItems(cartLineItems);
    // eslint-disable-next-line
  }, [totalItemQuantity]);

  useMemo(() => {
    if (smartCheckoutName && !loading && customerUser) {
      getSmartReminderConfig(
        smartCheckoutName.replaceAll("+", ""),
        "deployed",
        customerUser.id,
        productId
      )
        .then(({ data }) => {
          setShopifyDiscountCode(data.shopify_discount_code);
          if (data.redirect) {
            window.location.href = data.redirect;
            return;
          }
          if (data?.products.length) {
            data.products.forEach((productsObj: ProductPageConfiguration) => {
              if (productsObj.product) {
                productsObj.product.variants = getVariants(productsObj.product);
              }
              if (productsObj.product.last_purchase_at) {
                const product = {
                  ...productsObj.product,
                  previous_purchase_history: getPurchaseTime(
                    +productsObj.product.last_purchase_at
                  ),
                };
                productsObj = { ...productsObj, product: product };
              }
            });
            if (data.upsell_products.length > 0) {
              data.upsell_products = data?.upsell_products?.filter(
                (item) =>
                  !data.products.some(
                    (obj) => obj.product.product_id === item.product_id
                  )
              );

              data?.upsell_products?.forEach((item: Product) => {
                if (item?.last_purchase_at) {
                  item = {
                    ...item,
                    previous_purchase_history: getPurchaseTime(
                      +item.last_purchase_at
                    ),
                  };
                }
              });

              // data.upsell_products.forEach((obj: Product) => {
              //   obj.variants = getVariants(obj);
              // });
            }

            if (data.previous_purchases.length > 0) {
              data.previous_purchases.forEach((obj: Product) => {
                if (obj.last_purchase_at) {
                  obj = {
                    ...obj,
                    previous_purchase_history: getPurchaseTime(
                      +obj.last_purchase_at
                    ),
                  };
                }
              });
            }
          }
          const showBanners = data.products.every(
            (item) => item.add_banner_flag
          );
          const uniqueBanners = new Set(
            data.products.map((item) => item.banner_message)
          );
          setConfig({
            ...data,
            add_banner_flag: showBanners,
            banner_message:
              showBanners && uniqueBanners.size === 1
                ? uniqueBanners.values().next().value
                : "",
          });
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            setError(err?.response?.data?.error);
          }
        });
    }
    // eslint-disable-next-line
  }, [productId, loading, customerUser]);

  useEffect(() => {
    if (!config?.products.length) {
      return;
    }
    let tempCart: CartLineItem[] = [];

    // temp
    // const selectedVariant = config.product.variants.find(
    //   (item) => item.id === 1266
    // );
    // if (selectedVariant) {
    //   tempCart = [{ variant: selectedVariant, quantity: 5 }];
    // } else {
    //   tempCart = [{ variant: config.product.variants[0], quantity: 1 }];
    // }

    // eslint-disable-next-line array-callback-return
    if (config.products.length > 0) {
      config.products.forEach((productConfig: ProductPageConfiguration) => {
        if (
          productConfig.product &&
          productConfig.product.variants &&
          productConfig.product.variants[0] &&
          itemsInCart() === 0
        ) {
          if (
            productConfig.selected_variant_id &&
            productConfig.selected_quantity
          ) {
            const selectedVariant = productConfig.product.variants.find(
              (item: ProductVariant) =>
                item.id === productConfig.selected_variant_id
            );
            if (selectedVariant) {
              tempCart.push({
                variant: {
                  ...selectedVariant,
                  main_product_id: productConfig.product.product_id,
                },
                quantity: productConfig.selected_quantity,
                plan_id: getDefualtSubscription(productConfig),
              });
              // setCartLineItems([{ variant: selectedVariant, quantity: config.selected_quantity }]);
            } else {
              tempCart.push({
                variant: {
                  ...productConfig.product.variants[0],
                  main_product_id: productConfig.product.product_id,
                },
                quantity: 1,
                plan_id: getDefualtSubscription(productConfig),
              });
              // setCartLineItems([{ variant: config.product.variants[0], quantity: 1 }]);
            }
          } else {
            tempCart.push({
              variant: {
                ...productConfig.product.variants[0],
                main_product_id: productConfig.product.product_id,
              },
              quantity: 1,
              plan_id: getDefualtSubscription(productConfig),
            });
            // setCartLineItems([{ variant: config.product.variants[0], quantity: 1 }]);
          }
        }
      });
    } else {
      if (config.previous_purchases.length === 1) {
        config.previous_purchases.forEach((product: Product) => {
          if (product.variants && product.variants[0] && itemsInCart() === 0) {
            if (product.selected_variant_id && product.selected_quantity) {
              const selectedVariant = product.variants.find(
                (item: ProductVariant) =>
                  item.id === product.selected_variant_id
              );
              if (selectedVariant) {
                tempCart.push({
                  variant: selectedVariant,
                  quantity: product.selected_quantity,
                });
                // setCartLineItems([{ variant: selectedVariant, quantity: config.selected_quantity }]);
              } else {
                tempCart.push({
                  variant: product.variants[0],
                  quantity: 1,
                });
                // setCartLineItems([{ variant: config.product.variants[0], quantity: 1 }]);
              }
            } else {
              tempCart.push({
                variant: product.variants[0],
                quantity: 1,
              });
              // setCartLineItems([{ variant: config.product.variants[0], quantity: 1 }]);
            }
          }
        });
      }
    }
    if (tempCart?.length > 0) {
      setCartLineItems(tempCart);
      localStorage.setItem("cartItem", JSON.stringify(tempCart));
    }

    // eslint-disable-next-line
  }, [config]);

  useTrackPageVisit();
  if ((!config && !error) || !cart || isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!config && error) {
    return (
      <div className="flex min-h-screen w-full flex-col justify-center items-center px-4 xl:px-0">
        <div className="">
          <div className="text-2xl md:!text-6xl font-semibold mb-6 xl:!mb-14">
            404 - Product not found
          </div>
          <div className="">
            <button
              className="cursor-pointer text-sm rounded py-2 px-4 bg-blue hover:bg-blue-800 text-white font-medium"
              onClick={() => navigate("/")}
            >
              Homepage
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (completed && cartId && config) {
    return <Navigate to={`/order/${cartId}`} replace />;
  }

  const buyLaterProductHandle = (productId: string, variantId?: string) => {
    if (variantId) {
      if (customerUser) {
        saveProduct(customerUser.phone, variantId);
      }
      setBuyLaterProductIds([...buyLaterProductIds, productId]);
    }
  };

  return (
    <>
      <NavBar2
        middle={
          <div className="flex relative pl-4 w-full py-0 py-sm-4 bg-white sm:justify-end justify-between sm:h-16 h-8">
            {siteConfiguration?.image_url && (
              <img
                className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-8"
                src={siteConfiguration?.image_url}
                alt="Brand banner"
              ></img>
            )}
          </div>
        }
      />
      {config?.add_banner_flag && config?.banner_message !== "" && (
        <div
          className="py-[13px] tracking-wide text-center text-white text-xs font-extrabold font-boldAvenir"
          style={{ backgroundColor: siteConfiguration.primary_color }}
        >
          {config?.banner_message}
        </div>
      )}
      <div
        className={
          "main-contain flex justify-center items-start transition-all duration-500"
        }
      >
        <div className="max-w-full w-full">
          <div
            className={`cart-product-wrap cart-product-wrap-new pt-[10px] sm:pt-[29px] relative pb-28 sm:pb-8 ${
              config?.add_banner_flag
                ? "bg-h-with-banner"
                : "bg-h-without-banner"
            }`}
            style={{ backgroundColor: siteConfiguration.secondary_color }}
          >
            <div className="bg-white absolute h-36 top-0 w-full mobileProductBg hidden"></div>
            <div className="max-w-[1170px] mx-auto flex flex-row md:pr-4 pr-0 productPageContainer">
              <div className="lg:w-3/4 md:w-4/6 w-full h-auto">
                {config && config.products.length > 0 && (
                  <div className="px-4 relative z-10">
                    <p className="font-boldAvenir text-gray-variant/75 text-xs mb-2 mt-1 md:mb-4 md:text-gray-variant/75 md:text-sm md:tracking-wide md:font-blackAvenir uppercase">
                      You items
                    </p>
                    <div className="max-w-960 mx-auto">
                      {config?.products.map(
                        (obj: ProductPageConfiguration, index: number) => (
                          <ProductCard
                            key={index}
                            isTop={true}
                            fullConfig={config}
                            product={obj.product}
                            isSubscription={{
                              enable: obj.is_subscription_enabled,
                              default: obj.is_default_subscription,
                            }}
                            preSelectedVariantId={obj.selected_variant_id}
                            preSelectedQuantity={obj.selected_quantity}
                            main={true}
                            color={siteConfiguration.primary_color}
                            buyLaterProductIds={buyLaterProductIds}
                            buyLaterProductHandle={buyLaterProductHandle}
                            user={customerUser || null}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
                {config && config?.previous_purchases?.length > 0 && (
                  <div className="w-full px-4 md:pt-9">
                    <p className="relative uppercase font-boldAvenir text-gray-variant/75 text-xs mb-3 md:mb-4 md:text-gray-variant/75 md:text-sm md:tracking-wide md:font-blackAvenir">
                      Purchased Before
                    </p>
                    <div className="regimen-cards-wrap lg:grid lg:gap-x-4 lg:grid-cols-2">
                      {config?.previous_purchases?.map((product: Product) => (
                        <div key={product.id} className="mb-4">
                          <ProductCard
                            product={product}
                            main={true}
                            color={siteConfiguration.primary_color}
                            buyLaterProductIds={buyLaterProductIds}
                            buyLaterProductHandle={buyLaterProductHandle}
                            user={customerUser || null}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {config && config.upsell_products.length > 0 && (
                  <div className="w-full px-4 md:pt-9">
                    <p className="uppercase font-boldAvenir text-gray-variant/75 text-xs mb-3 md:mb-4 md:text-gray-variant/75 md:text-sm md:tracking-wide md:font-blackAvenir">
                      {config.upsell_text}
                    </p>
                    <div className="regimen-cards-wrap lg:grid lg:gap-x-4 lg:grid-cols-2">
                      {config?.upsell_products?.map((product: Product) => (
                        <div key={product.id} className="mb-4">
                          <ProductCard
                            product={product}
                            main={true}
                            color={siteConfiguration.primary_color}
                            buyLaterProductIds={buyLaterProductIds}
                            buyLaterProductHandle={buyLaterProductHandle}
                            user={customerUser || null}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <CartView
                cu={customerUser || null}
                totalCartItems={itemsInCart()}
                // cartPrice={cartPrice}
                config={config}
                configId={`${config?.product_page_configuration_id}`}
                // setCartPrice={setCartPrice}
                updateCustomerUser={refresh}
                reminder={false}
                discountCode={shopifyDiscountCode}
                utmMedium={utmMedium}
              />
            </div>
          </div>

          <CartPrice
            onSubmit={onSubmit}
            buttonColor={siteConfiguration.primary_color}
            configId={`${config?.product_page_configuration_id}`}
            totalCartItems={itemsInCart()}
            config={config}
            discountCode={shopifyDiscountCode}
            utmMedium={utmMedium}
          />
        </div>
      </div>
    </>
  );
};
