import { Navigate, Route, Routes } from "react-router-dom";
import { AuthPage } from "../pages/auth/AuthPage";
import { RequiresAuth } from "./RequiresAuth";
import { ErrorPage } from "../pages/error/ErrorsPage";
import { OrderHistoryPage } from "../pages/orderHistory/OrderHistoryPage";
import { OrderPage } from "../pages/order/OrderPage";
import { ProductPage } from "../pages/product/ProductPage";
import { ProfilePage } from "../pages/profile/ProfilePage";
import { PurchaseHistoryPage } from "../pages/purchaseHistory/PurchaseHistoryPage";
import { PurchasedProductsPage } from "../pages/purchaseProducts/PurchsedProductsPage";
import { SuccessPage } from "../pages/success/SuccessPage";
import { ContextProvider } from "./ContextProvider";
import BrandRedirect from "../BrandRedirect";
import { UnsubscribePage } from "../pages/unsubscribe/UnsubscribePage";
import { ProductPageNew } from "../pages/product/ProductPageNew";
import { RemindLaterPage } from "../pages/editReminder/RemindLaterPage";
import { EditReminderPage } from "../pages/editReminder/EditReminderPage";
import { ReminderInviation } from "../pages/editReminder/ReminderInviation";
import { SmartCheckoutPage } from "../pages/product/SmartCheckoutPage";
import UnsubscribeInvitePage from "../pages/unsubscribe/UnsubscribeInvitePage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ContextProvider />}>
        <Route path="/buy/:uuid" element={<ProductPage />} />
        <Route path="/buy/:uuid/:cuuid" element={<ProductPage />} />
        <Route path="/buy/all/:cuuid" element={<ProductPageNew />} />
        <Route path="/reminder/:uuid" element={<EditReminderPage />} />
        <Route
          path="/smart-checkout/:cuuid/:smartCheckoutName"
          element={<SmartCheckoutPage />}
        />
        <Route
          path="/smart-checkout/:cuuid/:smartCheckoutName/:productId"
          element={<SmartCheckoutPage />}
        />
        <Route
          path="/set/reminder/:reminder_email"
          element={<ReminderInviation />}
        />
        {/* manage reminder */}
        <Route
          path="/reminder/:uuid/reschedule"
          element={<RemindLaterPage />}
        />{" "}
        {/* remind me later */}
        <Route path="/unsubscribe/:uuid" element={<UnsubscribePage />} />
        <Route
          path="/unsubscribe/invitation/:email"
          element={<UnsubscribeInvitePage />}
        />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/" element={<RequiresAuth component={ProfilePage} />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
        <Route
          path="/profile"
          element={<RequiresAuth component={ProfilePage} />}
        />
        <Route
          path="/history"
          element={<RequiresAuth component={PurchaseHistoryPage} />}
        />
        <Route
          path="/products"
          element={<RequiresAuth component={PurchasedProductsPage} />}
        />
        <Route
          path="/order/:uuid"
          element={<RequiresAuth component={OrderPage} />}
        />
        <Route
          path="/myOrders/:uuid"
          element={<RequiresAuth component={OrderHistoryPage} />}
        />
      </Route>
      <Route path="/:brandName/:uuid" element={<BrandRedirect />} />
    </Routes>
  );
};

export default AppRoutes;
