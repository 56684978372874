import {
  ChangeEvent,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CartLineItem } from "../../../checkout/api/CartModel";
import { CartLineItem as CartLineItemMain } from "../../checkout/model/CartModel";
import {
  Product,
  ProductPageConfiguration,
  ProductPageConfigurationV2,
  ProductVariant,
  SellingPlan,
} from "../../../models/ConfigurationModel";
import { CartContext } from "../../../contexts/CartContext";
import { ProductSavePopUp } from "./ProductSavePopUp";
import pluralize from "pluralize";
import {
  OptionType,
  OptionTypeSelector,
} from "../../../components/OptionTypeSelector";
import { CustomerUserResponse } from "../../../models/CustomerUserModel";
import { RepeatReminderResponse } from "../../editReminder/model/ReminderModel";
import { ProductOption } from "../models/ProductModel";
import { Switch } from "../../../components/Switch";
import { SiteConfigurationContext } from "../../../contexts/SiteConfigurationContext";
import {
  removeFractionalPart,
  showDiscount,
  showPurchaseHistory,
} from "../../../util/helpers";

export const ReminderSelector = ({
  product,
  onChange,
  existingReminder,
  isFullWidth,
}: {
  product: Product | ProductVariant;
  onChange: (value: string) => void;
  existingReminder?: RepeatReminderResponse;
  isFullWidth?: boolean;
}) => {
  const steps = product.reminder_frequency.steps;
  const pauseStep = product.reminder_frequency.pause_step;
  const defaultOption = product.reminder_frequency.default_step_time_seconds;

  const pauseOption = {
    label: pauseStep.label,
    value: String(pauseStep.step_time_seconds),
  };
  const options: OptionType[] = [pauseOption];

  const existingOption = existingReminder?.paused
    ? -1
    : existingReminder?.period_seconds;

  const [selected, setSelected] = useState(
    String(existingOption || defaultOption)
  );

  const stepOptions: OptionType[] = steps.map(
    ({ frequency, step_time_seconds }) => {
      const { quantity, quality } = frequency;
      return {
        label: `Every ${quantity} ${pluralize(quality, quantity)}`,
        value: String(step_time_seconds),
      };
    }
  );

  const onSelected = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <OptionTypeSelector
      options={[...options, ...stepOptions]}
      currentOption={selected}
      placeholder="Set Reminder"
      isFullWidth={isFullWidth || false}
      setReminder={onSelected}
    />
  );
};

export const ProductCardV3 = memo(
  ({
    product,
    main,
    color,
    buyLaterProductIds,
    buyLaterProductHandle,
    isTop,
    user,
    preSelectedVariantId,
    preSelectedQuantity,
    isSubscription = { enable: false, default: false },
    fullConfig,
  }: {
    product: Product;
    main: boolean;
    color: string;
    buyLaterProductIds: string[];
    buyLaterProductHandle: (productId: string, variantId?: string) => void;
    isTop?: boolean;
    user: CustomerUserResponse | null;
    preSelectedVariantId?: number;
    preSelectedQuantity?: number;
    isSubscription?: { enable: boolean; default: boolean };
    fullConfig?: ProductPageConfigurationV2;
  }) => {
    const { siteConfiguration } = useContext(SiteConfigurationContext);
    const [alert, setAlert] = useState<Boolean>(false);

    const productVariants = useMemo(
      () => product.variants.filter((item) => +item.price > 0),
      [product.variants]
    );
    const getSellingPlanId = (plan: SellingPlan) => {
      return `${plan.discount_variant_id}_${plan.freq}`;
    };

    const [price, setPrice] = useState<string>(
      removeFractionalPart(productVariants[0]?.price)
    );
    const [active, setActive] = useState<boolean>(false);
    // const [singleDiscount, setSingleDiscount] = useState<string>("");

    const sellingPlans: SellingPlan[] = useMemo(
      () =>
        product.selling_plans
          .filter((item) => +item.value > 0)
          .map((plan) => ({ ...plan, plan_id: getSellingPlanId(plan) })),
      [product.selling_plans]
    );

    const qtyPickerOptions = Array(21)
      .fill(1)
      .map((item, i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ));
    const [cardStyle, setCardStyle] = useState({});
    const [currentPlan, setCurrentPlan] = useState<SellingPlan | undefined>();
    const originalOptions = product.options
      .filter(({ name }) => name !== "Title")
      .map((item) => ({ ...item, values: Array.from(new Set(item.values)) }));
    const [filterByTitle, setFilterByTitle] = useState<ProductOption[]>([
      ...originalOptions,
    ]);

    const { cart, setCart, updateCart } = useContext(CartContext);

    useEffect(() => {
      if (isSubscription.enable && isSubscription.default) {
        onSubscription();
      }
      // eslint-disable-next-line
    }, [isSubscription.default]);

    const [selectedVariant, setSelectedVariant] = useState<CartLineItem>(() => {
      if (preSelectedVariantId && preSelectedQuantity) {
        const matchedVariant = productVariants?.find(
          (item) => item.id === preSelectedVariantId
        );
        return {
          variant: matchedVariant ? matchedVariant : productVariants[0],
          quantity: preSelectedQuantity,
        };
      } else {
        return {
          variant: productVariants[0],
          quantity: 0,
        };
      }
    });

    const [selectedOption, setSelectedOption] = useState(() => {
      let cartLineItems: CartLineItem[] = [];
      if (fullConfig) {
        if (fullConfig?.products?.length > 0) {
          fullConfig.products.forEach(
            (productObj: ProductPageConfiguration) => {
              const temp = productObj.product.variants
                .filter((item) => +item.price > 0)
                .find(
                  (item: any) => item.id === productObj.selected_variant_id
                );
              if (temp) {
                cartLineItems.push({
                  variant: temp,
                  quantity: preSelectedQuantity,
                });
              }
            }
          );
        }
        if (fullConfig?.previous_purchases?.length > 0) {
          fullConfig.previous_purchases.forEach((productObj: any) => {
            const temp = productObj?.variants?.find(
              (item: any) => item.id === productObj.selected_variant_id
            );
            if (temp) {
              cartLineItems.push({
                variant: temp,
                quantity: preSelectedQuantity,
              });
            }
          });
        }
      }
      const variants = productVariants.map((item) => item.variant_id);
      const matchedCartItem = cartLineItems?.find((item) =>
        variants.includes(item?.variant?.variant_id)
      );

      const cartVariants = cartLineItems.map((item) => item.variant.variant_id);
      const matchedProduct = productVariants.find((item) =>
        cartVariants.includes(item?.variant_id)
      );
      const defaultSelection: any = {};
      if (matchedProduct) {
        matchedProduct.selected_options.forEach((option) => {
          defaultSelection[option.name] = option.value;
        });
        setPrice(removeFractionalPart(matchedProduct.price));

        setSelectedVariant({
          ...matchedCartItem,
          variant: matchedProduct,
        });
      } else {
        if (productVariants[0]) {
          productVariants[0].selected_options?.forEach((option) => {
            defaultSelection[option.name] = option.value;
          });
        }
      }
      return defaultSelection;
    });

    useEffect(() => {
      const initialQuantity = cart?.lineItems?.find(
        ({ variant }) =>
          variant?.variant_id === selectedVariant?.variant?.variant_id
      );
      setSelectedVariant({
        ...selectedVariant,
        quantity: initialQuantity ? initialQuantity.quantity : 0,
      });
      // eslint-disable-next-line
    }, [cart.lineItems]);

    const variantSubscriptions: SellingPlan[] = useMemo(
      () => {
        return sellingPlans
          .filter(
            (item) =>
              !item.variant_id ||
              item.variant_id === selectedVariant.variant.variant_id
          )
          .map((item) => ({ ...item, plan_id: getSellingPlanId(item) }));
      },
      // eslint-disable-next-line
      [selectedVariant]
    );

    const handleOptionChange = (optionName: string, optionValue: string) => {
      const selectedOptions: any = {
        ...selectedOption,
        [optionName]: optionValue,
      };

      const newOptions: ProductOption[] = originalOptions.map((item) => {
        if (item.name !== optionName) {
          return { name: item.name, values: [] };
        } else {
          return { ...item };
        }
      });
      productVariants.forEach((variant) => {
        if (
          variant.selected_options.some(
            (item) => item.name === optionName && item.value === optionValue
          )
        ) {
          variant.selected_options.forEach((opItem) => {
            if (opItem.name !== optionName) {
              const existingOption = newOptions.findIndex(
                (nItem) => nItem.name === opItem.name
              );
              if (existingOption === -1) {
                newOptions.push({ name: opItem.name, values: [opItem.value] });
              } else {
                newOptions[existingOption].values.push(opItem.value);
              }
            }
          });
        }
      });
      setFilterByTitle(newOptions);

      const possibleVariants = productVariants.filter((variant) =>
        variant.selected_options.some(
          (item) => item.name === optionName && item.value === optionValue
        )
      );
      if (!possibleVariants.length) {
        return;
      }

      const oldSelected = { ...selectedOption, [optionName]: optionValue };
      let getselectedVariant: any = null;
      const selectedOptionsVariant = possibleVariants?.find((variant) => {
        let selected = false;
        for (let i = 0; i < variant.selected_options.length; i++) {
          const option = variant.selected_options[i];
          selected =
            oldSelected[option.name] &&
            oldSelected[option.name] === option.value;
          if (!selected) {
            break;
          }
        }
        return selected;
      });
      if (!selectedOptionsVariant) {
        getselectedVariant = possibleVariants?.find((variant) =>
          variant.selected_options.some(
            (item) => item.name === optionName && item.value === optionValue
          )
        );
      } else {
        getselectedVariant = { ...selectedOptionsVariant };
      }
      setSelectedOption(selectedOptions);
      setPrice(removeFractionalPart(getselectedVariant?.price));
      const findProduct = cart?.lineItems?.find(
        ({ variant }) => variant?.variant_id === getselectedVariant?.variant_id
      );
      const productPlanId =
        findProduct && findProduct.plan_id ? findProduct.plan_id : "";
      const selected: CartLineItemMain = {
        variant: { ...getselectedVariant, main_product_id: product.product_id },
        quantity: findProduct ? findProduct.quantity : 0,
        plan_id: productPlanId,
      };

      selected.variant.subscription_price =
        getVariantSubscriptionPrice(selected);

      setCurrentPlan(getPlanById(productPlanId));
      setActive(selected.plan_id !== "");
      setSelectedVariant(selected);
      updateCart(selected);
    };

    const getPlanById = (planId: string) => {
      return sellingPlans.find((item) => item.plan_id === planId);
    };

    const handleQuantityChange = (e: ChangeEvent<HTMLSelectElement>) => {
      if (selectedVariant && Object.keys(selectedVariant).length > 0) {
        const currentProductVariant = {
          ...selectedVariant,
          ...currentPlan,
          quantity: Number(e.target.value),
          plan_id: currentPlan?.plan_id,
        };
        currentProductVariant.variant.main_product_id = product.product_id;
        setSelectedVariant(currentProductVariant);
        updateCart(currentProductVariant);
      }
    };

    const getVariantions = (value: number) => {
      if (value === 1) {
        return "select-1";
      } else if (value === 2) {
        return "select-2";
      } else {
        return "select-3";
      }
    };

    useEffect(() => {
      const getCardStyle = () => {
        if (
          selectedVariant &&
          selectedVariant?.quantity &&
          selectedVariant?.quantity > 0
        ) {
          return { border: `1px solid ${color}` };
        }
        return {};
      };

      setCardStyle(getCardStyle());
    }, [selectedVariant, color]);

    // const showSubscription =
    //   product.subscription_options &&
    //   product.subscription_options.length > 0 &&
    //   isSubscription.enable;

    const subscribeToSave = product.plan_discounted_price
      ? product.plan_discounted_price.includes("$")
        ? `$${removeFractionalPart(
            +price - +product.plan_discounted_price.replace("$", "")
          )}`
        : `${removeFractionalPart(
            product.plan_discounted_price.replace("%", "")
          )}%`
      : "";

    const onSelectPlan = (e: any) => {
      const tempCart = { ...cart };
      setSelectedVariant((existing: CartLineItem) => ({
        ...existing,
        plan_id: e.target.value,
      }));
      const newCurrentPlan = getPlanById(e.target.value);
      setCurrentPlan(newCurrentPlan);
      const variantSubscriptionPrice =
        getVariantSubscriptionPrice(selectedVariant);
      setCart({
        lineItems: tempCart.lineItems.map((item) => {
          if (item.variant.variant_id === selectedVariant.variant.variant_id) {
            return {
              ...item,
              ...newCurrentPlan,
              variant: {
                ...item.variant,
                subscription_price: variantSubscriptionPrice,
              },
            };
          }
          return item;
        }),
      });
      const tempSelected = { ...selectedVariant };
      tempSelected.variant.subscription_price = variantSubscriptionPrice;
      setSelectedVariant(tempSelected);
    };

    const onSubscription = () => {
      let newCurrentPlan: SellingPlan | undefined = undefined,
        variantSubscriptionPrice = "";
      if (variantSubscriptions && variantSubscriptions.length > 0) {
        setActive(!active);
        if (!active) {
          const newPlanId = variantSubscriptions[0]["plan_id"];
          newCurrentPlan = getPlanById(newPlanId);
          setCurrentPlan(newCurrentPlan);
          variantSubscriptionPrice =
            getVariantSubscriptionPrice(selectedVariant);
        }
      }
      const tempCart = { ...cart };
      // update cart
      setCart({
        lineItems: tempCart?.lineItems.map((item) => {
          if (
            item?.variant?.variant_id === selectedVariant?.variant?.variant_id
          ) {
            return {
              ...item,
              ...newCurrentPlan,
              variant: {
                ...item.variant,
                subscription_price: variantSubscriptionPrice,
              },
            };
          }
          return item;
        }),
      });

      // update selected variant subscription price
      const tempSelected = { ...selectedVariant };
      tempSelected.variant.subscription_price = variantSubscriptionPrice;
      setSelectedVariant(tempSelected);
    };

    const getVariantSubscriptionPrice = (
      freshSelectedVariant: CartLineItem
    ) => {
      const matchedPlan = sellingPlans.find(
        (item) => item.variant_id === freshSelectedVariant.variant.variant_id
      );
      if (matchedPlan) {
        return matchedPlan.value;
      }
      return "";
    };

    const SubscribeReminder = () => (
      <div className="flex items-center justify-between flex-wrap min-h-[56px] mobile__subscribe">
        <div className="flex items-center pr-[15px]">
          <Switch state={active} onClick={onSubscription} />
          <p className="pl-2 font-medium text-designerGray text-xs tracking-wide font-mediumAvenir">
            Subscribe{" "}
            {product?.plan_discounted_price && `& save ${subscribeToSave}`}
          </p>
        </div>
        <div className="pl-0">
          {active && variantSubscriptions && variantSubscriptions.length > 0 ? (
            <label className="select-label">
              <select
                name={"SubscribeRemind"}
                id={"SubscribeRemind"}
                value={currentPlan?.plan_id}
                onChange={onSelectPlan}
                className="py-2 h-8 sm:h-12 sm:pr-6 pr-5 pl-2 block my-1 truncate bg-white md:rounded-md md:py-3 border border-designerGray border-opacity-50 text-11"
              >
                {variantSubscriptions.map((optionValue) => (
                  <option
                    key={getSellingPlanId(optionValue)}
                    value={getSellingPlanId(optionValue)}
                  >
                    {optionValue.freq}
                  </option>
                ))}
              </select>
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    );

    const GetQuantityOptionView = () => (
      <>
        <div className="flex gap-3 justify-between">
          <div className="flex items-start dropBoxWrapper">
            {filterByTitle.length > 0 && (
              <div className="flex">
                {filterByTitle.map((option, index) => {
                  return (
                    <label
                      className={`select-label ${getVariantions(
                        filterByTitle?.length
                      )} ${
                        // className={`select-label select-1 ${
                        filterByTitle.length > 1 && "mr-2"
                      }`}
                      key={index}
                    >
                      <select
                        onChange={(e) =>
                          handleOptionChange(option.name, e.target.value)
                        }
                        key={option.name}
                        name={option.name}
                        id={option.name}
                        value={selectedOption[option.name]}
                        className="py-2 xl:pr-6 pr-5 pl-2 h-[32px] sm:h-[48px] block my-1 bg-white md:rounded-md md:py-3 border border-designerGray border-opacity-50 text-11 max-w-80px"
                      >
                        {option.values.map((optionValue, index) => (
                          <option key={index} value={optionValue}>
                            {optionValue}
                          </option>
                        ))}
                      </select>
                    </label>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <label className="select-label">
              <select
                onChange={(e) => handleQuantityChange(e)}
                value={selectedVariant && selectedVariant.quantity}
                className="border h-[32px] sm:h-[48px] xl:min-w-[61px] border-designerGray border-opacity-50 block my-1 bg-white md:rounded-md md:py-3 pr-8 pl-2 py-2 text-xs"
                name="quantity"
                id="quantity"
              >
                {qtyPickerOptions}
              </select>
            </label>
          </div>
        </div>
      </>
    );

    const TopProduct = () => (
      <div
        className={`flex mb-5 flex-col productCsBox justify-around bg-white rounded-lg border-0 relative ${
          main ? "shadow" : ""
        } ${variantSubscriptions?.length === 0 ? "subscibeNot" : ""}`}
        style={cardStyle}
      >
        <div className="flex mr-2 justify-between md:p-0 w-full">
          <div className="img-wrap relative w-12 md:w-40 flex items-center justify-center rounded-md xl:max-h-48 md:max-h-24">
            <img
              className="md:w-full h-full w-full rounded-md object-contain"
              src={
                selectedVariant.variant
                  ? selectedVariant.variant.image_url
                  : product.image_url
              }
              alt={product.title}
            />
          </div>

          <div
            className={`content-wrap due-order-new w-full relative flex flex-wrap content-between pt-[10px] pl-[6px] pb-[6px] pr-[10px] md:pt-[30px] md:pl-[32px] md:pb-[15px] md:pr-[19px]`}
          >
            <div className="flex justify-between w-full md:pl-0">
              <h1 className="heading text-sm sm:text-base font-boldAvenir sm:font-boldAvenir tracking-[0.4px] text-[#1d1d1b] productName">
                {product.title}
              </h1>
              {price && (
                <div className="text-right text-sm sm:text-base">
                  {(selectedVariant?.variant?.discounted_price > 0 ||
                    // (product.plan_discounted_price &&
                    (active &&
                      selectedVariant?.variant?.subscription_price > 0)) &&
                  showDiscount(
                    price,
                    selectedVariant?.variant?.discounted_price,
                    selectedVariant?.variant?.subscription_price,
                    active
                  ) ? (
                    <>
                      <p
                        className="font-mediumAvenir font-medium text-sm text-darkAssistBlue tracking-[0.4px] sm:text-base"
                        style={{ color: siteConfiguration?.primary_color }}
                      >
                        $
                        {active
                          ? removeFractionalPart(
                              selectedVariant?.variant?.subscription_price
                            )
                          : removeFractionalPart(
                              selectedVariant?.variant?.discounted_price
                            )}
                      </p>
                      <span className="text-sm relative">
                        <span
                          className="line-through absolute text-sm tracking-[0.4px] sm:text-base"
                          style={{
                            backgroundColor: siteConfiguration?.primary_color,
                          }}
                        ></span>
                        ${removeFractionalPart(price)}
                      </span>
                    </>
                  ) : (
                    <p className="font-mediumAvenir font-medium AvenirMediumtext tracking-[0.4px] text-sm sm:text-base">
                      ${removeFractionalPart(price)}
                    </p>
                  )}
                </div>
              )}
            </div>
            {/* <div className="product-content w-full lg:my-2 lg:mb-2 md:block hidden xl:min-h-35px lg:min-h-fit">
                <p className="text-designerGray text-xs tracking-wide font-normal">
                  {product.description}
                </p>
              </div> */}

            <div className="w-full">
              {variantSubscriptions.length > 0 && isSubscription.enable ? (
                <div className="align-middle md:my-2 md:mb-2 md:block hidden md:min-h-fit">
                  <SubscribeReminder />
                </div>
              ) : (
                ""
              )}

              {product?.purchase_count && product?.last_purchase_at !== "" ? (
                <div className="align-middle md:my-2 md:mb-2 md:block hidden md:min-h-fit">
                  <span className="text-xs font-mediumAvenir font-normal text-gray-variant3 tracking-wide">
                    Purchased {product?.purchase_count} times | Last purchase{" "}
                    {showPurchaseHistory(product?.last_purchase_at)}
                  </span>
                </div>
              ) : (
                ""
              )}

              <div className="flex-col flex-grow justify-end bottom-7 left-8 w-full xl:mt-0 md:pl-0">
                <GetQuantityOptionView />
              </div>
            </div>
          </div>
        </div>
        {variantSubscriptions.length > 0 && isSubscription.enable ? (
          <div className="flex-col flex-grow justify-end block md:hidden md:p-6 p-4">
            <SubscribeReminder />
          </div>
        ) : (
          ""
        )}
        {product?.purchase_count && product?.last_purchase_at !== "" ? (
          <div className="flex-col flex-grow justify-end block md:hidden md:p-6 px-4 pb-4">
            <span className="text-[11px] font-mediumAvenir font-normal text-gray-variant3 tracking-wide">
              Purchased {product?.purchase_count} times | Last purchase{" "}
              {showPurchaseHistory(product?.last_purchase_at)}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );

    const OtherProduct = () => (
      <div
        className={`flex flex-col justify-around bg-white rounded-lg border-0 relative ${
          main ? "shadow" : ""
        }`}
        style={cardStyle}
      >
        <div className="flex mr-2 p-4 pb-3 w-full regimen-cards-wrap-box">
          <div className="img-wrap relative w-12 h-12 flex items-center justify-center">
            <img
              className="w-12 h-12 rounded-md bg-white"
              src={
                selectedVariant.variant
                  ? selectedVariant.variant.image_url
                  : product.image_url
              }
              alt={product.title}
            />
          </div>

          <div className="content-wrap w-full pl-3">
            <div className="flex justify-between">
              <h1 className="font-boldAvenir sm:font-boldAvenir text-sm sm:text-base tracking-wide text-designerGray upsell__productName">
                {product?.title}
              </h1>
              {price && (
                <p className="font-mediumAvenir font-medium text-sm sm:text-base AvenirMediumtext">
                  ${removeFractionalPart(price)}
                </p>
              )}
            </div>

            <div className="w-full">
              <div className="flex-col flex-grow justify-end p-4 pb-2 pt-0 mobile__option__wrapper">
                <GetQuantityOptionView />
              </div>
            </div>
          </div>
        </div>
        {product?.purchase_count && product?.last_purchase_at !== "" ? (
          <div className="align-middle w-full px-4 md:my-2 md:mb-2 md:block hidden md:min-h-fit">
            <span className="text-xs font-mediumAvenir font-normal text-gray-variant3 tracking-wide">
              Purchased {product?.purchase_count} times | Last purchase{" "}
              {showPurchaseHistory(product?.last_purchase_at)}
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="flex-col flex-grow justify-end p-4 pb-2 pt-0 desktop__option__wrapper">
          <GetQuantityOptionView />
        </div>
        {product?.purchase_count && product?.last_purchase_at !== "" ? (
          <div className="flex-col flex-grow justify-end block md:hidden md:p-6 px-4 pb-4">
            <span className="text-[11px] font-mediumAvenir font-normal text-gray-variant3 tracking-wide">
              Purchased {product?.purchase_count} times | Last purchase{" "}
              {showPurchaseHistory(product?.last_purchase_at)}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );

    return (
      <>
        {isTop ? <TopProduct /> : <OtherProduct />}
        <ProductSavePopUp alert={alert} setAlert={setAlert} />
      </>
    );
  }
);
