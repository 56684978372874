import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LineItem, OrderResponse, Tracking } from "../order/model/OrderModel";
import { getOrder } from "../order/api/orderApi";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";

import { Loading } from "../../components/Loading";

import {
  formatNumber,
  getAddressString,
  getZipString,
} from "../../util/helpers";

export const OrderHistoryPage = () => {
  const { uuid } = useParams<{ variant: string; uuid: string }>();

  const [order, setOrder] = useState<OrderResponse>();

  useEffect(() => {
    if (!order && uuid) {
      getOrder(uuid).then(({ data }) => setOrder(data));
    }
  }, [uuid, order]);

  return order ? <OrderView order={order} /> : <Loading />;
};

const TrackingSection = ({ tracking }: { tracking: Tracking }) => {
  return (
    <div>
      <p className="text-sm font-extrabold uppercase text-gray-variant">
        Delivery Date: {tracking.estimated_delivery_date}
      </p>
      <div className="mt-2">
        <p className="text-sm">
          {tracking.company} tracking number:{" "}
          <a href={tracking.url}>{tracking.number}</a>
        </p>
      </div>
    </div>
  );
};

const OrderLineItem = ({ item }: { item: LineItem }) => {
  const product = item.product;

  return (
    <div className="flex flex-1">
      <div className="border-gray-300 border rounded-md overflow-hidden">
        <img
          src={product.image_url}
          height="32"
          width="32"
          alt={product.alt_text || product.variant_id}
        />
      </div>
      <div className="ml-2">
        <div className="flex items-center">
          <p className="text-xs font-bold font-mediumAvenir">{product.name}</p>
          {item.quantity > 1 && (
            <div className="bg-black rounded-full h-4 w-4 ml-1 flex items-center justify-center">
              <p className="text-white text-xs">{item.quantity}</p>
            </div>
          )}
        </div>
        <p className="text-xs text-gray-variant3">
          {product.variant_name === "Default Title" ? (
            <br />
          ) : (
            product.variant_name
          )}
        </p>
      </div>
      <div className="justify-self-end flex-1">
        <p className="text-xs text-right">
          ${formatNumber(Number(product.price) * item.quantity)}
        </p>
      </div>
    </div>
  );
};

const OrderView = ({ order }: { order: OrderResponse }) => {
  const { customerUser } = useContext(CustomerUserContext);

  const navigate = useNavigate();

  return (
    <>
      <div className="order-history-wrap bg-gray-50 absolute top-0 left-0 w-full h-screen pt-8">
        <div className="top-wrap flex justify-center">
          <div className="container max-w-screen-sm mx-auto relative">
            <div className="back-btn w-7 absolute left-5 mt-1">
              <button onClick={() => navigate("/")}>
                <img
                  src="/back-arrow.svg"
                  alt="back-icon"
                  className="h-5 w-5"
                ></img>
              </button>
            </div>
            <div className="title-wrap text-center">
              <h4 className="font-bold font-mediumAvenir tracking-wide">
                Order {order.order_name}
              </h4>
            </div>
          </div>
        </div>
        <div className="container max-w-screen-sm mx-auto text-gray-700">
          <div className="md:mt-16 mt-3 md:bg-white rounded-md md:shadow-shadow1 md:p-10 py-8 px-4">
            <div className="flex items-center justify-between mb-4">
              <p className="text-xs font-bold font-mediumAvenir tracking-wide text-gray-variant">
                Items
              </p>
              <p className="text-xs tracking-wide">
                ${formatNumber(Number(order.subtotal_price))}
              </p>
            </div>
            <div className="mt-4">
              {order.line_items.map((o) => (
                <div key={o.product.name} className="mt-3">
                  <OrderLineItem item={o} />
                </div>
              ))}
            </div>
            <div className="flex items-center justify-between mt-4">
              <p className="text-xs">Shipping & Handling</p>
              <p className="text-xs">
                ${formatNumber(Number(order.total_shipping_price))}
              </p>
            </div>
            <div className="flex items-center justify-between mt-2">
              <p className="text-xs">Taxes</p>
              <p className="text-xs">
                ${formatNumber(Number(order.total_tax))}
              </p>
            </div>
            <div className="flex items-center justify-between mt-2">
              <p className="text-sm font-bold font-mediumAvenir">Order Total</p>
              <p className="text-sm font-bold font-mediumAvenir">
                ${formatNumber(Number(order.total_price))}
              </p>
            </div>
            {order.tracking && (
              <div className="mt-8">
                <TrackingSection tracking={order.tracking} />
              </div>
            )}
            <div className="mt-8">
              <p className="text-xs font-bold font-mediumAvenir mb-2">
                {customerUser?.name}
              </p>
              {order.shipping_address && (
                <p className="uppercase text-xs text-gray-600">
                  {getAddressString(order.shipping_address)}
                  <br />
                  {getZipString(order.shipping_address)}
                </p>
              )}
            </div>
            <div className="mt-8"></div>
          </div>
        </div>
      </div>
    </>
  );
};
