import { useContext } from "react";
import { SiteConfigurationContext } from "../contexts/SiteConfigurationContext";

const Switch = ({
  state,
  onClick,
}: {
  state: boolean;
  onClick: () => void;
}) => {
  const { siteConfiguration } = useContext(SiteConfigurationContext);
  return (
    <div
      className={`toggle w-10 lg:w-[45px] lg:h-6 h-5 rounded-full relative cursor-pointer `}
      style={{
        backgroundColor: state
          ? siteConfiguration?.primary_color
          : "rgb(156 163 175)",
      }}
      onClick={onClick}
    >
      <span
        className={`lg:w-5 lg:h-5 h-4 w-4 rounded-full bg-white absolute  top-1/2 transform -translate-y-1/2 shadow-toggle_shadow transition-all ${
          state ? "left-5 lg:left-[22px]" : "left-1 lg:left-[3px]"
        } `}
      ></span>
    </div>
  );
};

export { Switch };
