import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

// Inspired by:
// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token

export interface UseAPI<T> {
  data: T | null;
  loading: boolean;
  error: any | null;
  refresh: () => void;
}

export interface APIState<T> {
  data: T | null;
  loading: boolean;
  error: any | null;
}

export interface GetAccessTokenSilentlyOptions {
  audience?: string;
  scope?: string;
}

const { REACT_APP_API_AUDIENCE } = process.env;

export function useApi<T, D = any>(
  config: AxiosRequestConfig<D>,
  options?: GetAccessTokenSilentlyOptions,
  client: AxiosInstance = axios
): UseAPI<T> {
  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState<APIState<T>>({
    error: null,
    loading: true,
    data: null,
  });

  const [refreshIndex, setRefreshIndex] = useState(0);

  const { cuuid } = useParams<{ cuuid: string }>();

  useEffect(() => {
    setState({ ...state, loading: true });
    (async () => {
      try {
        const accessToken = cuuid
          ? cuuid
          : await getAccessTokenSilently({
              ...options,
              audience: REACT_APP_API_AUDIENCE,
            });
        client
          .request<T>({
            ...config,
            headers: {
              ...config.headers,
              // Add the Authorization header to the existing headers
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(({ data }) => {
            setState({
              ...state,
              data,
              error: null,
              loading: false,
            });
          })
          .catch((error) => {
            setState({
              ...state,
              error,
              loading: false,
            });
          });
      } catch (e: any) {
        // const params = new URLSearchParams(window.location.search);
        // if (!params.has('code') && e.error === 'login_required') {
        //   loginWithRedirect();
        // }
        // if (e.error === 'consent_required') {
        //   loginWithRedirect();
        // }
        setState({
          ...state,
          data: null,
          error: e,
          loading: false,
        });
      }
    })();
    //eslint-disable-next-line
  }, [refreshIndex]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
}
