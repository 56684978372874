import React from "react";

const NavBar2 = ({
  left,
  middle,
  right,
}: {
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
}) => {
  return (
    <>
      <div
        className={`relative max-w-7xl mx-auto sm:py-[11px] pt-[9px] pb-[10px]`}
      >
        <div className="w-full flex items-center relative">
          {left}
          {middle}
        </div>
        {right}
      </div>
    </>
  );
};

export default NavBar2;
