import React, { createContext } from "react";
import { useGetCustomerUser } from "../api/customerUserApi";
import { CustomerUserResponse } from "../models/CustomerUserModel";

export const CustomerUserContext = createContext<{
  customerUser?: CustomerUserResponse | null;
  setCustomerUser: (user: CustomerUserResponse) => void;
  loading: boolean;
  error: any | null;
  refresh: () => void;
}>({
  loading: false,
  error: null,
  refresh: () => {},
  setCustomerUser: () => {},
});

export const CustomerUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loading, error, refresh, data: customerUser } = useGetCustomerUser();
  const [user, setUser] = React.useState<CustomerUserResponse>();

  React.useEffect(() => {
    if (customerUser) {
      setUser(customerUser);
    }
  }, [customerUser]);
  return (
    <CustomerUserContext.Provider
      value={{
        customerUser: user,
        setCustomerUser: setUser,
        refresh,
        loading,
        error,
      }}
    >
      {children}
    </CustomerUserContext.Provider>
  );
};
