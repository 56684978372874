import { useEffect, useState } from "react";
import { OptionTypeSelector } from "../../../components/OptionTypeSelector";
import { ProductVariant } from "../../../models/ConfigurationModel";
import { frequencySelectOptions } from "../../../util/products";

export const ProductReminder = ({
  shopifyVariant,
  currentOption,
  setRecurrence,
  periodSeconds,
}: {
  shopifyVariant: ProductVariant;
  currentOption: string;
  setRecurrence: (v: string) => void;
  periodSeconds: number;
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([
    { label: "Don’t remind me", value: "-1" },
    ...frequencySelectOptions(shopifyVariant),
  ]);

  useEffect(() => {
    if (periodSeconds) {
      if (!options.find((item) => +item.value === periodSeconds)) {
        let temp = [...options];
        const secondFrames = {
          HOUR: 3600,
          DAY: 86400,
          WEEK: 604800,
          MONTH: 2592000,
        };
        // create new option
        if (periodSeconds % secondFrames.MONTH === 0) {
          const value = periodSeconds / secondFrames.MONTH;
          temp.push({
            label: `Every ${value} ${value > 1 ? "months" : "month"}`,
            value: String(periodSeconds),
          });
        } else if (periodSeconds % secondFrames.WEEK === 0) {
          const value = periodSeconds / secondFrames.WEEK;
          temp.push({
            label: `Every ${value} ${value > 1 ? "weeks" : "week"}`,
            value: String(periodSeconds),
          });
        } else if (periodSeconds % secondFrames.DAY === 0) {
          const value = periodSeconds / secondFrames.DAY;
          temp.push({
            label: `Every ${value} ${value > 1 ? "days" : "day"}`,
            value: String(periodSeconds),
          });
        } else if (periodSeconds % secondFrames.HOUR === 0) {
          const value = periodSeconds / secondFrames.HOUR;
          temp.push({
            label: `Every ${value} ${value > 1 ? "hours" : "hour"}`,
            value: String(periodSeconds),
          });
        }
        temp = temp.sort((a, b) => (+a.value > +b.value ? 1 : -1));
        setOptions(temp);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex mr-2 md:mr-0 md:pt-6 pt-5 w-full  md:pb-5 pb-3.5">
        <div className="img-wrap relative w-20 h-20">
          <img
            // className="md:w-full object-contain md:h-full w-20 h-20 rounded-md bg-gray-100"
            className="md:w-full md:h-full object-contain w-20 h-20 rounded-md bg-gray-100"
            src={shopifyVariant?.image_url}
            // src={
            //   "https://cdn.shopify.com/s/files/1/0628/1435/5673/products/WGELIMAGE1_5000x_070e1b2b-e1f3-4557-a4d6-d5dd2f0a3e6a_823x.jpg?v=1646873336"
            // }
            alt={shopifyVariant.alt_text || shopifyVariant.name}
          />
        </div>

        <div className="w-8/12 pl-4 flex flex-col">
          <h4 className="font-semibold font-mediumAvenir text-sm mb-2 text-designerGray tracking-wide">
            {shopifyVariant.name}
          </h4>
          {shopifyVariant.variant_name !== "Default Title" && (
            <span className="text-designerGray text-opacity-75 tracking-wide text-xs">
              {shopifyVariant.variant_name}
            </span>
          )}
        </div>
      </div>
      <OptionTypeSelector
        placeholder="Start Reminder"
        options={options}
        currentOption={currentOption}
        setReminder={(v: string) => setRecurrence(v)}
        isFullWidth={true}
      />
    </>
  );
};
