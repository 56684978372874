import { ReactNode, useContext, useState } from "react";
import {
  CartResponse,
  Checkout,
  LineItem,
  PurchaseRequestPayload,
} from "../../checkout/model/CheckoutModel";
import { CustomerUserResponse } from "../../../models/CustomerUserModel";
import { CartPriceDrawers } from "./CartPriceDrawers";
import { purchaseCartV2 } from "../api/CartsV2Requests";
import { useAuth0 } from "@auth0/auth0-react";
// import { useNavigate } from "react-router-dom";
import { CustomerUserContext } from "../../../contexts/CustomerUserContext";
import { CartContext } from "../../../contexts/CartContext";
import { ProductPageConfigurationV2 } from "../../../models/ConfigurationModel";
import { useParams } from "react-router-dom";
import {
  getAddressString,
  getZipString,
  removeFractionalPart,
  showDiscount,
} from "../../../util/helpers";
import { SiteConfigurationContext } from "../../../contexts/SiteConfigurationContext";

export const ShippingDetailList = ({
  customerUser,
  setCurrentEditItem,
}: {
  customerUser?: CustomerUserResponse;
  setCurrentEditItem: (newEditItem: string) => void;
}) => {
  const LineItem = ({
    label,
    children,
    last = false,
    onClick,
  }: {
    label: string;
    children: ReactNode;
    last: boolean;
    onClick?: () => void;
  }) => {
    return (
      <div className={`flex items-start flex-wrap`}>
        <p
          className={`text-blue1 font-medium text-sm w-auto ${
            onClick && "cursor-pointer"
          }`}
          onClick={onClick}
        >
          {label}
        </p>
        <div className="addressDetails">{children}</div>
        <div />
      </div>
    );
  };
  if (
    customerUser &&
    // customerUser.default_payment_method &&
    customerUser.default_shipping_address
  ) {
    const { default_shipping_address } = customerUser;

    return (
      <div className="flex flex-col font-mediumAvenir">
        <LineItem
          onClick={() => setCurrentEditItem("ship_to")}
          label="Ship to"
          last={true}
        >
          <p className="text-sm font-mediumAvenir font-medium">
            {getAddressString(default_shipping_address.address)}{" "}
            {getZipString(default_shipping_address.address)}
            {/* {default_shipping_address.address.address1} */}
          </p>
        </LineItem>
        {/* {GetEmail(customerUser, LineItem)}
        <LineItem label="Phone" last={true}>
          <p className="text-sm">{customerUser?.phone}</p>
        </LineItem> */}
      </div>
    );
  }

  return <></>;
};

const DefaultDetails = ({
  customerUser,
  setCurrentEditItem,
}: {
  customerUser: CustomerUserResponse;
  setCurrentEditItem: (newEditItem: string) => void;
}) => {
  const [expand, setExpand] = useState(false);

  return (
    <div className="firstTop">
      {expand ? (
        <div className="flex flex-col">
          <button
            className="text-blue-500 text-xs self-end"
            onClick={() => setExpand(false)}
          >
            Close
          </button>

          <ShippingDetailList
            customerUser={customerUser}
            setCurrentEditItem={setCurrentEditItem}
          />
        </div>
      ) : (
        <div className="flex items-center topHead flex-wrap">
          <div className="shipToText">
            <p
              className={`text-sm cursor-pointer font-mediumAvenir`}
              onClick={() => setCurrentEditItem("ship_to")}
            >
              Ship to
            </p>
          </div>
          <div className="addressDetails">
            <p className="text-sm font-mediumAvenir">
              {customerUser.default_shipping_address ? (
                <>
                  {getAddressString(
                    customerUser.default_shipping_address.address
                  )}{" "}
                  {getZipString(customerUser.default_shipping_address.address)}
                </>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const { REACT_APP_API_AUDIENCE: audience } = process.env;

export const CartPrice = ({
  onSubmit,
  buttonColor,
  configId,
  // cartPrice,
  totalCartItems,
  overrideReturnTo,
  // discounts,
  config,
  discountCode,
  utmMedium,
}: {
  onSubmit: (c: CartResponse) => void;
  // cartPrice: string;
  totalCartItems: number;
  buttonColor: string;
  configId?: string;
  overrideReturnTo?: string;
  // discounts?: { [key: string]: string };
  config?: ProductPageConfigurationV2;
  discountCode?: string;
  utmMedium: string | null;
}) => {
  const { siteConfiguration } = useContext(SiteConfigurationContext);
  const { cart, cartPrice } = useContext(CartContext);

  const { getAccessTokenSilently } = useAuth0();

  const [processing, setProcessing] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState("");

  const { customerUser, refresh } = useContext(CustomerUserContext);
  const { cuuid } = useParams<{ cuuid: string }>();

  const purchase = async (event: any) => {
    event.preventDefault();

    if (!customerUser) {
      return;
    }

    const isLegacy = cart.lineItems.some((item) => item.discount_variant_id);

    const lineItems = cart.lineItems.map((p) => {
      const item: LineItem = {
        variantId: p.variant.variant_id,
        quantity: p.quantity,
        planId: p.plan_id,
      };
      if (!p.plan_id || isLegacy) {
        delete item.planId;
      }

      if (p.discount_variant_id) {
        item.variantId = `gid://shopify/ProductVariant/${p.discount_variant_id}`;
        item.shippingIntervalFrequency = p.shipping_interval_frequency;
        item.shippingIntervalUnitType = p.shipping_interval_unit_type;
        item.subscriptionId = p.subscription_id;
      }
      return item;
    });

    let shippingAddress = null;
    let billingAddress = null;
    if (
      customerUser != null &&
      customerUser.default_shipping_address != null &&
      customerUser.default_shipping_address.address != null
    ) {
      shippingAddress = customerUser.default_shipping_address.address;
      billingAddress = customerUser.default_shipping_address.address;
    }
    const co: Checkout = {
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      lineItems,
      hasSubscription: lineItems.some(
        (item) => item.planId || item.shippingIntervalFrequency
      ),
      phone: customerUser?.phone,
      utmTags: window.location.search.substr(1),
      productPageConfigurationId: `${configId}`,
    };

    if (discountCode) {
      co.discountCode = discountCode;
    }

    const payload: PurchaseRequestPayload = {
      cart: co,
      utm_medium: utmMedium ?? "sms",
      customer_id: customerUser.id,
    };

    if (cuuid) {
      payload.logged_in = true;
    }

    if (isLegacy) {
      payload.cart.is_legacy = true;
    }
    setProcessing(true);
    getAccessTokenSilently({ audience })
      .then((accessToken) => purchaseCartV2(payload, accessToken))
      .then(({ data }) => {
        setProcessing(false);
        window.location.href = data.invoice_url;
      })
      .catch((r) => {
        if (customerUser) {
          purchaseCartV2(payload, customerUser.uuid).then(({ data }) => {
            setProcessing(false);
            window.location.href = data.invoice_url;
            // onSubmit(data);
          });
        } else {
          setProcessing(false);
        }
      });
  };

  const Loading = () => {
    return (
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-8 h-8 border-4 border-blue-400 border-solid rounded-full animate-spin"
      />
    );
  };

  const canBuyNow = customerUser;
  // const returnTo = overrideReturnTo || `/checkout/${configId}`;
  // const hasAnyDiscount = () => discounts && Object.keys(discounts).length > 0;
  return (
    <>
      <div className="fixed mobileFixedCart bottom-0 left-0 right-0 shadow-drawer_shadow p-3 bg-white md:hidden block z-10">
        <div
          className={`overlay bg-black bg-opacity-70 h-full fixed top-0 left-0  transition-all ease-in-out duration-500 ${
            currentEditItem !== "" ? "opacity-1 z-0 w-full " : "opacity-0 z-mz"
          }`}
        ></div>
        {canBuyNow && customerUser.default_shipping_address && (
          <DefaultDetails
            customerUser={customerUser}
            setCurrentEditItem={setCurrentEditItem}
          />
        )}

        <div className="flex justify-between items-center secondBottom relative max-w-960 mx-auto">
          {/* <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H5C5.35747 0.25 5.66526 0.502289 5.73541 0.852807L6.61551 5.25H23C23.2235 5.25 23.4354 5.3497 23.5779 5.52192C23.7204 5.69414 23.7786 5.92093 23.7367 6.1405L22.1357 14.5361C22.0099 15.1692 21.6655 15.7378 21.1628 16.1425C20.662 16.5456 20.0359 16.7605 19.3934 16.75H9.68664C9.04405 16.7605 8.41799 16.5456 7.91725 16.1425C7.41473 15.738 7.07043 15.1697 6.94455 14.537L5.27255 6.18328C5.26672 6.16005 5.26197 6.13639 5.25836 6.11235L4.38524 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM6.91573 6.75L8.41563 14.2439C8.47278 14.5316 8.62932 14.7901 8.85785 14.9741C9.08637 15.158 9.37232 15.2558 9.66563 15.2501L9.68 15.25H19.4L19.4144 15.2501C19.7077 15.2558 19.9936 15.158 20.2222 14.9741C20.45 14.7906 20.6063 14.5331 20.6639 14.2463L20.6644 14.2439L22.0935 6.75H6.91573ZM7.25003 21C7.25003 20.0335 8.03353 19.25 9.00003 19.25C9.96653 19.25 10.75 20.0335 10.75 21C10.75 21.9665 9.96653 22.75 9.00003 22.75C8.03353 22.75 7.25003 21.9665 7.25003 21ZM18.25 21C18.25 20.0335 19.0335 19.25 20 19.25C20.9665 19.25 21.75 20.0335 21.75 21C21.75 21.9665 20.9665 22.75 20 22.75C19.0335 22.75 18.25 21.9665 18.25 21Z"
              fill="#2F363D"
            />
          </svg>
          <div className="bg-designerOrange absolute left-4 top-1 h-5 w-5 rounded-full flex items-center justify-center">
            <p className="text-white text-sm">{totalCartItems || 0}</p>
          </div> */}
          <div className="text-left flex-1 leftWrapper">
            {cartPrice.discounted > 0 &&
            showDiscount(cartPrice.original, cartPrice.discounted) ? (
              <div className="d-flex">
                <span className="text-xs relative mr-2 inline-flex items-center">
                  <span
                    className="line-through absolute"
                    style={{
                      backgroundColor: siteConfiguration?.primary_color,
                    }}
                  ></span>
                  ${removeFractionalPart(cartPrice.original)}
                </span>
                <span
                  className="font-bold font-mediumAvenir text-sm tracking-wide text-darkAssistBlue"
                  style={{
                    color: siteConfiguration?.primary_color,
                  }}
                >
                  ${removeFractionalPart(cartPrice.discounted)}
                </span>
              </div>
            ) : (
              <p className="font-bold font-mediumAvenir text-sm tracking-wide">
                ${removeFractionalPart(cartPrice.original)}
              </p>
            )}
            <p className="text-sm">
              {/* {customerUser ? "with tax & shipping" : "before tax & shipping"} */}
              Before tax & shipping
            </p>
          </div>
          {processing ? (
            <Loading />
          ) : (
            <button
              className="checkoutBtn w-[98px] py-1 px-4 rounded-lg text-white block h-10 font-mediumAvenir"
              style={{ backgroundColor: buttonColor }}
              onClick={purchase}
            >
              Buy now
            </button>
          )}
          {/* {!canBuyNow && !processing && (
            <button
              className="py-1 px-4 rounded-lg text-white block h-10"
              style={{ backgroundColor: buttonColor }}
              onClick={() => navigate(returnTo)}
            >
              Checkout
            </button>
          )} */}
          {/* {processing && <Loading />} */}
        </div>
        <CartPriceDrawers
          setCurrentEditItem={setCurrentEditItem}
          currentEditItem={currentEditItem}
          refresh={refresh}
        />
      </div>
    </>
  );
};
