/* eslint-disable react-hooks/rules-of-hooks */
import { Address } from "../pages/checkout/model/CheckoutModel";
import * as yup from "yup";
import {
  Cart,
  Product,
  ProductVariants,
} from "../pages/product/models/ProductModel";
import { Frequency } from "../pages/editReminder/model/FrequencyModel";
import { ProductPageConfiguration } from "../models/ConfigurationModel";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { subscriptionTypes } from "./constants";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

export const formatNumber = (num: number) =>
  (Math.round(num * 100) / 100).toFixed(2);

export const oneLinerAddress = (address: Address) => {
  return `${address.address1} ${address.address2} ${address.city}, ${address.province_code} ${address.zip}`;
};

export const onInputFocus = (
  fields: string[],
  setFields: (data: string[]) => void,
  name: string
) => {
  setFields([...fields, name]);
};

export const onInputBlur = (
  fields: string[],
  setFields: (data: string[]) => void,
  name: string
) => {
  const tempFields = [...fields];
  if (tempFields.indexOf(name) > -1) {
    setFields([...tempFields.filter((field) => field !== name)]);
  }
};

export const getClass = (
  fieldName: any,
  values: any,
  focusedFields: string[]
) => {
  const classes = [];
  if (focusedFields.includes(fieldName)) {
    classes.push("focused ");
  }
  if (values && values[fieldName]) {
    classes.push("has-value");
  }
  return classes.join(" ");
};

export const getAddressString = (address: Address) => {
  const { address1, address2, city, province_code } = address;
  return [address1, address2, city, province_code]
    .filter((item) => item)
    .join(", ");
};

export const getZipString = (address: Address) => {
  const { zip, country_code } = address;
  return [zip, country_code].filter((item) => item).join(", ");
};

export const validateValues = (
  values: any,
  schema: yup.AnyObjectSchema,
  setErrors: (errors: any) => void
) => {
  const tempErrors: any = {};
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (e: any) {
    e.inner.forEach((item: yup.ValidationError) => {
      if (item.path) {
        tempErrors[item.path] = item.message;
      }
    });
  }
  setErrors(tempErrors);
  return Object.keys(tempErrors).length > 0;
};

export const basicPhoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

export const daysToSeconds = (days: number) => days * 86400;
export const weeksToSeconds = (weeks: number) => weeks * 604800;
export const hoursToSeconds = (hours: number) => hours * 3600;

export const frequencyToSeconds = (freq: Frequency) => {
  if (freq.quality === "week") {
    return weeksToSeconds(freq.quantity);
  } else if (freq.quality === "hour") {
    return hoursToSeconds(freq.quantity);
  } else {
    return daysToSeconds(freq.quantity);
  }
};

export const getShippingDescription = (title: string) => {
  if (title === "Standard") {
    return "(3 to 4 business days)";
  } else if (title === "Express") {
    return "(1 to 2 business days)";
  }
  if (title === "Economy") {
    return "(5 to 8 business days)";
  }
};

export const changeCartQuantity = (
  currentProductVariants: ProductVariants,
  shopifyCartConfig: Cart,
  setShopifyCartConfig: (cart: Cart) => void
) => {
  const tempFormattedCart = { ...shopifyCartConfig };
  tempFormattedCart.product.variants.forEach((mainVariant: ProductVariants) => {
    if (mainVariant.id === currentProductVariants.id) {
      mainVariant.quantity = currentProductVariants.quantity;
    }
  });
  tempFormattedCart.collection.forEach((subProduct: Product) => {
    subProduct.variants.forEach((subVariant) => {
      if (subVariant.id === currentProductVariants.id) {
        subVariant.quantity = currentProductVariants.quantity;
      }
    });
  });
  setShopifyCartConfig(tempFormattedCart);
};

export const removeFractionalPart = (value: string | number) => {
  if (+value % 1 === 0) {
    return Math.trunc(+value).toString();
  } else {
    return (+value).toFixed(2);
  }
};

export const validateEmail = (input: string) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

// check if discounted price or subscription price is different then original price of the product
export const showDiscount = (
  price: string | number,
  discountedPrice: string | number,
  subPrice: string = "",
  hasSub: boolean = false
) => {
  if (hasSub) {
    return price !== subPrice;
  }
  return price !== discountedPrice;
};

// get the previous purchase history in the cart
export const getPurchaseTime = (time: number) => {
  if (time < 7) {
    return time > 1 ? `${time} days` : `${time} day`;
  } else if (time < 60) {
    const week = Math.floor(time / 7);
    return week > 1 ? `${week} weeks` : `${week} week`;
  } else {
    const month = Math.floor(time / 30);
    return `${month} months`;
  }
};

// get pass a default subscripation option planId
export const getDefualtSubscription = (
  productConfig: ProductPageConfiguration
) => {
  if (
    productConfig?.is_subscription_enabled &&
    productConfig?.is_default_subscription &&
    productConfig?.product?.subscription_options &&
    productConfig.product.subscription_options.length > 0
  ) {
    return productConfig.product.subscription_options[0]["plan_id"];
  }
  return "";
};

export const showPurchaseHistory = (strDate: string) => {
  const date = new Date(strDate);
  if (date) {
    return timeAgo.format(new Date(date));
  }
  return null;
};

export const calculateDiscount = (price: string, discountPercent: string) => {
  // disocunt from shopify store
  return ((+price * (100 - +discountPercent)) / 100).toFixed(2);
};

export const calculateSubscriptionPrice = (
  mainPrice: string,
  subscriptionValue: string,
  subscriptionType: string
) => {
  if (subscriptionType === subscriptionTypes.PERCENTAGE) {
    const newPrice = (+mainPrice * (100 - +subscriptionValue)) / 100;
    return (newPrice < 0 ? 0 : newPrice).toFixed(2);
  } else {
    return subscriptionValue;
  }
};

// discount logic by fixed amount and percentage
export const getDiscountPrice = (
  price: string,
  type: string,
  discountValue: number
) => {
  let newPrice = 0;
  if (type === "fixed_amount") {
    newPrice = +price - discountValue;
  } else {
    newPrice = +price - (discountValue * +price) / 100;
  }
  return newPrice < 0 ? "0.00" : newPrice.toFixed(2);
};
