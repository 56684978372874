import Select, { components } from "react-select";
import { Frequency } from "../model/FrequencyModel";
import { OptionType } from "../../../components/OptionTypeSelector";

export const DelayReminderSelector = ({
  frequency,
  updateFrequency,
  placeholder,
  isFullWidth,
}: {
  frequency: Frequency;
  updateFrequency: (freq: Frequency) => void;
  placeholder: string;
  isFullWidth?: boolean;
}) => {
  var quantityOptions: OptionType[] = [];

  for (var i = 0; i <= 30; i++) {
    quantityOptions.push({ label: String(i), value: String(i) });
  }

  const qualityOptions: OptionType[] = [
    // { value: "hour", label: "Hour(s)" },
    { value: "day", label: "Day(s)" },
    { value: "week", label: "Week(s)" },
  ];

  // const [frequency, setFrequency] = useState<Frequency>({
  //   quantity: 1,
  //   quality: "day",
  // });

  const handleQuantityChange = (newQuantity: string) => {
    // setFrequency({ ...frequency, quantity: newQuantity });
    updateFrequency({ ...frequency, quantity: parseInt(newQuantity) });
  };

  const handleQualityChange = (newQuality: "day" | "week" | "hour") => {
    // const handleQualityChange = (newQuality: "day" | "week") => {
    // setFrequency({ ...frequency, quality: newQuality });
    updateFrequency({ ...frequency, quality: newQuality });
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="px-1">
          <img src="/down-arrow.svg" alt="arrow img" />
        </div>
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className={`dropdown-wrap sm:mt-0 mt-1 flex ${
        isFullWidth && "md:w-full"
      }`}
    >
      <Select
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #808080",
          }),
        }}
        components={{ DropdownIndicator }}
        isSearchable={false}
        className="rounded w-20 border-0.5 border-borderGrey-greyBorder text-xs font-mediumAvenir text-bold border-opacity-50"
        classNamePrefix="dp"
        options={quantityOptions}
        placeholder="After"
        defaultValue={quantityOptions[0]}
        onChange={(e: any) => handleQuantityChange(e.value)}
      />

      <Select
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #808080",
          }),
        }}
        components={{ DropdownIndicator }}
        isSearchable={false}
        className="ml-4 rounded w-40 border-0.5 border-borderGrey-greyBorder text-xs font-mediumAvenir text-bold border-opacity-50"
        classNamePrefix="dp"
        options={qualityOptions}
        placeholder="After"
        defaultValue={qualityOptions.find(
          // (item: OptionType) => item.value === "hour"
          (item: OptionType) => item.value === "day"
        )}
        onChange={(e: any) => handleQualityChange(e.value)}
      />
    </div>
  );
};
