import axios from "axios";
import { ProductPageConfigurationV2 } from "../models/ConfigurationModel";
import { SiteConfiguration } from "../contexts/SiteConfigurationContext";

const API_URL = process.env.REACT_APP_API_URL;

export type RevisionType = "working" | "deployed";

// export const getProductPageConfig = (
//   uuid: string,
//   utm_medium: string,
//   revision_type: RevisionType = "deployed",
//   customerPhone: string = "",
//   customerEmail: string = ""
// ) =>
//   axios.get<ProductPageConfigurationV2>(
//     utm_medium === "email"
//       ? `${API_URL}/product_page_configurations/${uuid}/reminder?revision=${revision_type}&email=${customerEmail}`
//       : `${API_URL}/product_page_configurations/${uuid}/reminder?revision=${revision_type}&phone_number=${customerPhone}`
//   );

export const getProductPageConfig = (
  uuid: string,
  revision_type: RevisionType = "deployed",
  customer_id: string
) => {
  return axios.get<ProductPageConfigurationV2>(
    `${API_URL}/product_page_configurations/${uuid}/reminder?revision=${revision_type}&customer_id=${customer_id}&business_id=${process.env.REACT_APP_BUSINESS_ID}`
  );
};

export const getSmartReminderConfig = (
  smartCheckoutName: string,
  revisionType: RevisionType = "deployed",
  customerId: string,
  productId?: string
) => {
  if (productId) {
    return axios.get<ProductPageConfigurationV2>(
      `${API_URL}/product_page_configurations/${smartCheckoutName}/smart_checkout?revision=${revisionType}&customer_id=${customerId}&business_id=${process.env.REACT_APP_BUSINESS_ID}&product_id=${productId}`
    );
  } else {
    return axios.get<ProductPageConfigurationV2>(
      `${API_URL}/product_page_configurations/${smartCheckoutName}/smart_checkout?revision=${revisionType}&customer_id=${customerId}&business_id=${process.env.REACT_APP_BUSINESS_ID}`
    );
  }
};

export const getAllProductPageConfig = async (
  customer_id: string,
  cuuid: string
) => {
  return axios.get<ProductPageConfigurationV2>(
    `${API_URL}/customer_users/cart?customer_id=${customer_id}&business_id=${process.env.REACT_APP_BUSINESS_ID}`,
    { headers: { Authorization: `Bearer ${cuuid}` } }
  );
};

export const getSiteConfiguration = (
  businessId: string,
  revision_type: RevisionType = "deployed",
  reminderUUID?: string
) => {
  if (reminderUUID) {
    return axios.get<SiteConfiguration>(
      `${API_URL}/businesses/${businessId}/site_configurations?revision=${revision_type}&reminder_uuid=${reminderUUID}`
    );
  } else {
    return axios.get<SiteConfiguration>(
      `${API_URL}/businesses/${businessId}/site_configurations?revision=${revision_type}`
    );
  }
};
