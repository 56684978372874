import {
  Product,
  ProductPageConfigurationV2,
} from "../../../models/ConfigurationModel";
import { CustomerUserResponse } from "../../../models/CustomerUserModel";
import { ProductCardV2 } from "./ProductCardV2";
import { ProductCardV3 } from "./ProductCardV3";

interface ProductV2 {
  product: Product;
  main: boolean;
  color: string;
  buyLaterProductIds: string[];
  buyLaterProductHandle: (productId: string, variantId?: string) => void;
  isTop?: boolean;
  user: CustomerUserResponse | null;
  preSelectedVariantId?: number;
  preSelectedQuantity?: number;
  isSubscription?: { enable: boolean; default: boolean };
  fullConfig?: ProductPageConfigurationV2;
}

export const ProductCard = (props: ProductV2) => {
  if (props.product) {
    if (props.isSubscription?.enable) {
      const hasSellingPlans =
        props.product.selling_plans && props.product.selling_plans.length > 0;
      if (
        (!props.product.subscription_options && hasSellingPlans) ||
        (props.product.subscription_options.length === 0 && hasSellingPlans)
      ) {
        return <ProductCardV3 {...props} />;
      }
    }
    return <ProductCardV2 {...props} />;
  } else {
    return (
      <div
        className={`flex mb-5 flex-col productCsBox justify-around bg-white rounded-lg border-0 relative ${
          props.main ? "shadow" : ""
        }`}
      >
        <div className="flex font-boldAvenir tracking-[0.4px] justify-center align-middle py-12 md:py-28">
          <h1>Product is not in a stock</h1>
        </div>
      </div>
    );
  }
};
