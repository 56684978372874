import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SiteConfigurationContext } from "../../contexts/SiteConfigurationContext";
import { CustomerUserResponse } from "../../models/CustomerUserModel";
import { getReminder } from "../editReminder/api/reminderApi";
import { unsubscribe } from "./api/allowListApi";

const UnsubscribePage = () => {
  const params = useParams();

  const navigate = useNavigate();
  const { siteConfiguration } = useContext(SiteConfigurationContext);
  const [cUser, setCUser] = useState<CustomerUserResponse>();
  const [ubSubType, setUnSubType] = useState("");
  const [success, setSuccess] = useState<Boolean>(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getReminderData();
    // eslint-disable-next-line
  }, []);

  const getReminderData = () => {
    if (params.uuid) {
      getReminder(params.uuid)
        .then(({ data }) => {
          if (data.customer_user) {
            setCUser(data.customer_user);
          }
        })
        .catch(() => {
          navigate("/404");
        });
    }
  };
  //
  const onUnsubscribe = () => {
    if (params.uuid && process.env.REACT_APP_BUSINESS_ID) {
      setSubmitting(true);
      unsubscribe({
        business_id: process.env.REACT_APP_BUSINESS_ID,
        unsubscribe_type: ubSubType,
        uuid: params.uuid,
      })
        .then((res) => {
          setSubmitting(false);
          if (res.data && res.data.status === 200) {
            setSuccess(true);
          }
        })
        .catch((e) => {
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <div className="sm:bg-designerGray2 relative bg-[#F6FAFC] min-h-screen w-full">
        <div
          className=" mx-auto max-w-[800px] h-screen sm:h-auto"
          style={{ backgroundColor: siteConfiguration?.secondary_color }}
        >
          <div className="text-center bg-white h-[123px] sm:h-[98px] flex justify-center items-center">
            <img
              className="mx-auto sm:h-[35px] h-[46px]"
              alt="brand-logo"
              src={siteConfiguration?.image_url}
            ></img>
          </div>
          <div
            className="pt-12 pb-7 px-4"
            style={{ backgroundColor: siteConfiguration?.secondary_color }}
          >
            <div className="max-w-[470px] bg-white rounded-lg mx-auto border border-designerGray border-opacity-10 sm:p-8 p-4 mb-5">
              {success ? (
                <div className="text-center">
                  <p>You have successfully unsubscribed from the reminders.</p>
                  {/* <div className="mt-2 text-blue-600">
                    <a href="https://www.assistalong.com/">Go To Homepage</a>
                  </div> */}
                </div>
              ) : (
                <>
                  <div className="border-b mb-6 pb-6">
                    <h2 className="text-xl text-designerGray opacity-90 mb-4 font-extrabold font-blackAvenir text-center sm:text-left">
                      No more reorder reminders?
                    </h2>
                    <p className="text-xs text-designerGray-light font-body">
                      For: {cUser?.email ? cUser?.email : "---"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-xs sm:text-sm font-mediumAvenir text-designerGray mb-5">
                      Which reminders are you unsubscribing from?
                    </h4>
                    <div className="cst-radio">
                      <label className="block text-xs relative pl-7 mb-5 text-[11px]">
                        <input
                          type="radio"
                          name="unsubscribing_reminder"
                          className="absolute left-0 top-0 w-4 h-4 border-gray-300"
                          onChange={() => setUnSubType("existing")}
                          id="existing"
                        />
                        <span className="inline-block text-designerGray">
                          Not interested in these products anymore but I want to
                          keep my reminders for other products.
                        </span>
                      </label>
                      <label className="block text-[11px] relative pl-[30px]">
                        <input
                          type="radio"
                          name="unsubscribing_reminder"
                          className="absolute left-0 top-0 w-4 h-4 border-gray-300"
                          onChange={() => setUnSubType("all")}
                          id="all"
                        />
                        <span>
                          No reminders for any product from{" "}
                          {siteConfiguration?.brand_name}
                        </span>
                      </label>
                    </div>
                    <button
                      type="button"
                      className={`sm:mt-7 mt-[58px] mb-[79px] sm:mb-[53px] text-xs font-mediumAvenir text-white py-3.5 px-7 rounded-md sm:w-[178px] w-full ${
                        (!ubSubType || submitting) && "cursor-not-allowed"
                      }`}
                      disabled={!ubSubType || submitting}
                      style={{
                        backgroundColor:
                          !ubSubType || submitting
                            ? "gray"
                            : siteConfiguration?.primary_color,
                      }}
                      onClick={() => onUnsubscribe()}
                    >
                      Unsubscribe
                    </button>
                  </div>
                </>
              )}
            </div>
            {siteConfiguration && !siteConfiguration?.is_white_label && (
              <div className="max-w-[470px] mx-auto text-center pt-20 absolute bottom-5 left-1/2 -translate-x-1/2 sm:relative sm:bottom-0 sm:left-0 sm:translate-x-0 sm:block hidden">
                <p className="flex items-center justify-center text-designerGray text-opacity-75 font-semibold tracking-wide text-8 mx-auto">
                  Powered by{" "}
                  <img
                    className="ml-2 h-4"
                    alt="brand-logo"
                    src="/assistalong 2.svg"
                  ></img>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { UnsubscribePage };
