import { useState } from "react";
import { PersonalInformation } from "../../checkout/model/CheckoutModel";
import { getClass, onInputBlur, onInputFocus } from "../../../util/helpers";

export const PersonalInformationFields = ({
  initial,
  onPersonalInformationChange,
  phoneDisabled = false,
  enablePhoneInput = true,
  errors = {},
}: {
  initial?: PersonalInformation;
  onPersonalInformationChange: (i: PersonalInformation) => void;
  phoneDisabled?: boolean;
  enablePhoneInput?: boolean;
  errors?: any;
}) => {
  const [focusedFields, setFocusedFields] = useState<string[]>([]);
  return (
    <div className="flex flex-col">
      {enablePhoneInput && (
        <>
          <div
            className={`field-wrap mt-2 ${getClass(
              "phone",
              initial,
              focusedFields
            )}`}
          >
            <label className="input-label">Phone Number</label>
            <input
              required
              type="tel"
              id="phone"
              autoComplete="phone"
              className={`input-field ${phoneDisabled && "text-gray-400"}`}
              value={initial?.phone}
              onChange={(e) =>
                onPersonalInformationChange({ phone: e.target.value })
              }
              onFocus={() =>
                onInputFocus(focusedFields, setFocusedFields, "phone")
              }
              onBlur={() =>
                onInputBlur(focusedFields, setFocusedFields, "phone")
              }
              disabled={phoneDisabled}
            />
          </div>
          <p className="text-danger text-xs">{errors["phone"]}</p>
        </>
      )}
      <div
        className={`field-wrap mt-2 ${getClass(
          "name",
          initial,
          focusedFields
        )}`}
      >
        <label className="input-label">Full Name</label>
        <input
          required
          type="text"
          id="fullname"
          autoComplete="name"
          className="input-field"
          placeholder=""
          value={initial?.name || ""}
          onFocus={() => onInputFocus(focusedFields, setFocusedFields, "name")}
          onBlur={() => onInputBlur(focusedFields, setFocusedFields, "name")}
          onChange={(e) =>
            onPersonalInformationChange({ name: e.target.value })
          }
        />
      </div>
      <p className="text-danger text-xs">{errors["name"]}</p>

      <div
        className={`field-wrap mt-2 ${getClass(
          "email",
          initial,
          focusedFields
        )}`}
      >
        <label className="input-label">Email</label>
        <input
          required
          type="text"
          id="email"
          autoComplete="email"
          className="input-field"
          placeholder=""
          inputMode="email"
          value={initial?.email || ""}
          onChange={(e) =>
            onPersonalInformationChange({ email: e.target.value })
          }
          onFocus={() => onInputFocus(focusedFields, setFocusedFields, "email")}
          onBlur={() => onInputBlur(focusedFields, setFocusedFields, "email")}
        />
      </div>
      <p className="text-danger text-xs">{errors["email"]}</p>
    </div>
  );
};
