import { ProductVariant } from "../../../models/ConfigurationModel";
import { useState } from "react";
import {
  createReminder,
  updateReminder,
} from "../../editReminder/api/reminderApi";
import {
  CreateReminderReq,
  UpdateReminderReq,
} from "../../editReminder/model/ReminderModel";
import { ReminderSelector } from "../../product/components/ProductCardV2";

export const ProductBox = ({
  product,
  index,
  phone,
}: {
  product: ProductVariant;
  index: any;
  phone: string;
}) => {
  const [existingReminder, setExistingReminder] = useState(
    product.existing_reminder
  );

  const onChange = (value: string) => {
    if (existingReminder) {
      const req: UpdateReminderReq = {
        period_seconds: value,
        rescheduled: true,
      };

      updateReminder(existingReminder.uuid, req).then(({ data }) =>
        setExistingReminder(data)
      );
    } else {
      const req: CreateReminderReq = {
        to: phone,
        period_seconds: value,
        variant_id: product.variant_id,
        quantity: 1,
      };

      createReminder(phone, req).then(({ data }) => setExistingReminder(data));
    }
  };

  const isAvailable = product?.available && product.repurchase_url;
  const isActive = product?.is_active_on_channel;

  // isAvailable && not isActive => not available (disabled)
  // not isAvailable && not isActive => not available (disabled)
  // not isAvailable && isActive => sold out
  // isAvailable && isActive => purchase

  const getButton = () => {
    if ((isAvailable && !isActive) || (!isAvailable && !isActive)) {
      // not available (disabled)
      return (
        <button
          className="btn cursor-not-allowed disabled:opacity-50 bg-blue-500 border-none"
          disabled
        >
          Not Available
        </button>
      );
    } else if (!isAvailable && isActive) {
      // sold out (disabled)
      return (
        <button
          className="btn cursor-not-allowed disabled:opacity-50 bg-blue-500 border-none"
          disabled
        >
          Sold Out
        </button>
      );
    } else {
      // isAvailable && isActive => purchase
      return (
        <button
          className={`btn`}
          onClick={() => {
            window.location.href = product.repurchase_url || "#";
          }}
        >
          <a
            href={product.repurchase_url || "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchase
          </a>
        </button>
      );
    }
  };

  return (
    <div className="products-wrap">
      <div className="bg-white p-5 mb-4">
        <div className="flex mb-4 items-start">
          <div className="w-12 h-12">
            <img
              className="rounded-lg w-full"
              src={`${product.image_url}`}
              alt={product.alt_text || product.name}
            ></img>
          </div>
          <div className="w-8/12 pl-4 flex flex-col justify-center">
            <h4 className="font-semibold font-mediumAvenir text-sm mb-2 text-designerGray tracking-wide">
              {product.name}
            </h4>
            <span className="text-designerGray text-opacity-75 tracking-wide text-xs">
              {product.variant_name === "Default Title" ? (
                <br />
              ) : (
                product.variant_name
              )}
            </span>
          </div>
          <div className="w-4/12 text-sm font-semibold font-mediumAvenir text-right">
            ${product.price}
          </div>
        </div>
        <div className="flex justify-between flex-wrap">
          <div className="dropdown-wrap w-4/6 pr-3">
            <ReminderSelector
              isFullWidth={true}
              product={product}
              onChange={onChange}
              existingReminder={existingReminder || undefined}
            />
          </div>
          <div className="w-2/6 text-right inline-flex">{getButton()}</div>
        </div>
      </div>
    </div>
  );
};
