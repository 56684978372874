import {
  CustomerUserAddress,
  CustomerUserAddressRequest,
  CustomerUserResponse,
} from "../../../models/CustomerUserModel";
import { addAddress } from "../../../api/customerUserApi";
import {
  getAddressString,
  getZipString,
  validateValues,
} from "../../../util/helpers";
import { Address } from "../../checkout/model/CheckoutModel";
import * as yup from "yup";

export const ShipTo = ({
  addresses,
  defaultId,
  setDefaultId,
  customerUser,
  viewFrom,
}: {
  addresses: CustomerUserAddress[];
  defaultId: number | undefined;
  setDefaultId: (id: number, index: number) => void;
  customerUser: CustomerUserResponse | null;
  viewFrom: string;
}) => {
  return (
    <>
      {addresses.map((addr, index) => (
        <div key={`${viewFrom}_${addr.id}_${defaultId}`}>
          <div
            className={`address flex items-start py-2 ${
              !(addresses.length === index + 1) && "border-b"
            }`}
          >
            <div className="input-wrap">
              <input
                type="radio"
                name={`shipTo_${viewFrom}`}
                id={`shipTo_${viewFrom}_${index}`}
                defaultChecked={addr.id === defaultId}
                onChange={() => setDefaultId(addr.id, index)}
              ></input>
            </div>
            <label htmlFor={`shipTo_${viewFrom}_${index}`}>
              <div className="mt-1 ml-2">
                <h3 className="text-xs tracking-wide text-designerGray font-bold font-mediumAvenir">
                  {addr.fullname}
                </h3>

                <p className="text-xs uppercase text-designerGray text-opacity-70 tracking-wide">
                  {getAddressString(addr.address)} {getZipString(addr.address)}
                </p>
              </div>
            </label>
          </div>
        </div>
      ))}
    </>
  );
};

export const saveAddress = async ({
  customerUser,
  newAddress,
  setErrors,
  addresses,
  updateCustomerUser,
  setAddresses,
  setDefaultId,
  setShowNewAddress,
  setIsAddressSaveButtonDisabled,
  accessToken,
  fullname,
}: {
  customerUser: CustomerUserResponse | null;
  newAddress: Address;
  setErrors: (data: any) => void;
  updateCustomerUser: () => void;
  addresses: CustomerUserAddress[];
  setAddresses: (addresses: CustomerUserAddress[]) => void;
  setDefaultId: (id: number) => void;
  setShowNewAddress: (value: boolean) => void;
  setIsAddressSaveButtonDisabled: (value: boolean) => void;
  accessToken: string;
  fullname: string;
}) => {
  if (!customerUser) return;

  const addressSchema = yup.object().shape({
    address1: yup.string().required("Street Address is required"),
    city: yup.string().required("City is required"),
    province_code: yup.string().required("State is required"),
    zip: yup.string().required("Zip Code is required"),
    fullname: yup.string().required("Name is required"),
  });

  const hasErrors = validateValues(
    { ...newAddress, fullname },
    addressSchema,
    setErrors
  );

  const uniqueAddress = addresses.some(
    (obj) => obj.address.address1 === newAddress.address1
  );

  if (uniqueAddress) {
    setErrors({ addressErr: "Selected address already exist" });
  }

  if (hasErrors || uniqueAddress) return;

  setIsAddressSaveButtonDisabled(true);

  const req: CustomerUserAddressRequest = {
    address_attributes: newAddress,
    address_type: "shipping",
    default: true,
    fullname,
  };

  const response = await addAddress(customerUser.phone, req, accessToken);

  if (response.data) {
    setAddresses([...customerUser.shipping_addresses, response.data]);
    setDefaultId(response.data.id);
    setShowNewAddress(false);
    updateCustomerUser();
  }

  setIsAddressSaveButtonDisabled(false);
  return true;
};
