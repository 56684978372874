import axios from "axios";
import {
  Calculation,
  CartCalculationResponse,
  CartResponse,
  PurchaseRequestPayload,
} from "../../checkout/model/CheckoutModel";

const API_URL = process.env.REACT_APP_API_URL;
const BUSINESS_ID = process.env.REACT_APP_BUSINESS_ID;

const BUSINESS_CARTSV2_URL = `${API_URL}/businesses/${BUSINESS_ID}/carts_v2`;
const PURCHASEV2_URL = `${BUSINESS_CARTSV2_URL}/checkout`;
const CALCULATEV2_URL = `${BUSINESS_CARTSV2_URL}/calculate`;

export const calculateCartV2 = (calc: Calculation) =>
  axios.post<CartCalculationResponse>(CALCULATEV2_URL, { cart: calc });

// export const purchaseCartV2 = (
//   co: CheckoutV2Request,
//   accessToken: string,
//   utmMedium: string,
//   customerId: string,
//   cuuid?: string
// ) => {
export const purchaseCartV2 = (
  payload: PurchaseRequestPayload,
  accessToken: string
) => {
  return axios.post<CartResponse>(PURCHASEV2_URL, payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
