import { useNavigate } from "react-router";
// import { useLocation } from "react-router-dom";
import NavBar2 from "../../../components/Navbar2";
// import { useSaveHomepage } from "../../product/components/use-save-homepage";

export const ProfileTabs = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate();

  // const { home } = useSaveHomepage(false);

  // const location = useLocation();

  // const searchParams = new URLSearchParams(location.search);
  // const shopify = searchParams.get("shopify");

  // const onClick = () => {
  //   if (home && !shopify) {
  //     navigate(home);
  //   } else {
  //     navigate(-1);
  //   }
  // };

  // const onClick = () => {
  //   if (home && !shopify) {
  //     navigate(home);
  //   } else {
  //     navigate(-1);
  //   }
  // };

  return (
    <div>
      {/* {/ <div className="hidden md:block"> /} */}
      <div className="tab-section pb-5">
        <NavBar2
          // left={
          //   <div className="back-btn mr-4 z-[999] left-0 absolute top-[50%] translate-y-[-50%]">
          //     <span className="cursor-pointer" onClick={() => onClick()}>
          //       <img src="\back-arrow.svg" alt=""></img>
          //     </span>
          //   </div>
          // }
          middle={
            <div className="flex px-[36px] relative w-full py-4  sm:justify-end justify-between h-16">
              <img
                className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-8 left-[50%] translate-x-[-50%]"
                src="\assistalong 2.svg"
                alt=""
              ></img>
            </div>
          }
        />
        <div className="tab-container-wrap mt-4 md:mt-6">
          <div className="tabs-wrap">
            <div className="tab-title">
              <ul className="flex border-b border-black border-opacity-50">
                <li
                  className="mr-8"
                  onClick={() => navigate("/profile", { replace: true })}
                >
                  <span
                    className={`text-xxs md:text-sm ${
                      activeTab === "profile" ? "tab-active" : "tab"
                    }`}
                  >
                    Profile
                  </span>
                </li>
                <li
                  className="mr-8"
                  onClick={() => navigate("/products", { replace: true })}
                >
                  <span
                    className={`text-xxs md:text-sm ${
                      activeTab === "products" ? "tab-active" : "tab "
                    }`}
                  >
                    My Products
                  </span>
                </li>
                <li
                  className="mr-8"
                  onClick={() => navigate("/history", { replace: true })}
                >
                  <span
                    className={`text-xxs md:text-sm ${
                      activeTab === "history" ? "tab-active" : "tab "
                    }`}
                  >
                    Purchase History
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
