import AppRoutes from "./routing/Routes";
import axios from "axios";
import ahoy from "ahoy.js";
import { BrowserRouter } from "react-router-dom";
import setupAxios from "./util/configs";

const { REACT_APP_API_URL }: any = process.env;

export const SAVED_PRODUCTS_KEY = "AA_CART_PRODUCTS";

ahoy.configure({
  urlPrefix: REACT_APP_API_URL,
  withCredentials: true,
});

function App() {
  setupAxios(axios);

  if (process.env.REACT_APP_KLAVIYO_KEY !== undefined) {
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.REACT_APP_KLAVIYO_KEY}`;
    head.appendChild(script);
  }

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
